import React,{useEffect} from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from './pages/Layout.js';
import Home from './pages/Home.js';
import About from './pages/About.js';
import Contact from './pages/Contact.js';
import BookConsultation from './pages/BookConsultation.js';
import Login from './pages/Login.js';
import Register from './pages/Register.js';
import Vision from './pages/Vision.js';
import Formula from './pages/Formula.js';
import Ethos from './pages/Ethos.js';
import FormulaDetailPage from './pages/FormulaDetailPage.js';
import ServiceDetailPage from './pages/ServiceDetailPage.js';
import TutorService from './pages/TutorService.js';
import Faq from './pages/Faq.js';
import Blog from './pages/Blog.js';
import TutorsQualification from './pages/TutorsQualification.js';
import TeachingMethod from './pages/TeachingMethod.js';
import PersonalisedLearningPlan from './pages/PersonalisedLearningPlan.js';
import FlexibleScheduling from './pages/FlexibleScheduling.js';
import ProgressTracking from './pages/ProgressTracking.js';
import EqualityPolicy from './pages/EqualityPolicy.js';
import Tutors from './pages/Tutors.js';
import SafeguardingPolicy from './pages/SafeguardingPolicy.js';
import PreventPolicy from './pages/PreventPolicy.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import CookiePolicy from './pages/CookiePolicy.js';
import AOS from 'aos';
import 'aos/dist/aos.css';

export const ScrollToTopOnRouteChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null; // This component doesn’t render anything
};


function App() {

  useEffect(() => {
    // Initialize AOS
    AOS.init({
      duration: 800,
      easing: 'slide',
      once: true,
    });

    // Refresh AOS on component re-renders if necessary
    AOS.refresh();
  }, []);
  

  return (
    <Layout>
    <ScrollToTopOnRouteChange />
    <Routes>
      <Route path="/" element={
        <Home/>
      }/>
      <Route path="/about" element={
        <About/>
      }/>
      <Route path="/contact" element={
        <Contact/>
      }/>
      <Route path="/book-consultation" element={
        <BookConsultation/>
      }/>
      <Route path="/login" element={
        <Login/>
      }/>
      <Route path="/register" element={
        <Register/>
      }/>
      <Route path="/vision" element={
        <Vision/>
      }/>
      <Route path="/kbm-formula" element={
        <Formula/>
      }/>
      <Route path="/ethos" element={
        <Ethos/>
      }/>
      <Route path="/category/:name" element={
        <FormulaDetailPage/>
      }/>
      <Route path="/service/:name" element={
        <ServiceDetailPage/>
      }/>
      <Route path="/tutoring-services" element={
        <TutorService/>
      }/>
      <Route path="/faq" element={
        <Faq/>
      }/>
      <Route path="/blogs" element={
        <Blog/>
      }/>
      <Route path="/tutors-qualification" element={
        <TutorsQualification/>
      }/>
      <Route path="/teaching-method" element={
        <TeachingMethod/>
      }/>
      <Route path="/personalised-learning-plan" element={
        <PersonalisedLearningPlan/>
      }/>
      <Route path="/flexible-scheduling" element={
        <FlexibleScheduling/>
      }/>
      <Route path="/progress-tracking" element={
        <ProgressTracking/>
      }/>
      <Route path="/equality-policy" element={
        <EqualityPolicy/>
      }/>
      <Route path="/our-tutors" element={
        <Tutors/>
      }/>
      <Route path="/safeguarding-policy" element={
        <SafeguardingPolicy/>
      }/>
      <Route path="/prevent-policy" element={
        <PreventPolicy/>
      }/>
      <Route path="/privacy-policy" element={
        <PrivacyPolicy/>
      }/>
      <Route path="/cookie-policy" element={
        <CookiePolicy/>
      }/>
      
    </Routes>
    </Layout>
  );
}

export default App;
