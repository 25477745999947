import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const TeachingMethod = () => {
	return (
		<>

		<MetaData title="Teaching Methods" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Teaching Methods</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Teaching Methods</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
           At KBM Tutors, our teaching methods are both constructive and creative, tailored to meet each student's unique learning needs. We focus on building a positive learning experience that enables students to reach their full potential. 
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Lesson Formatting</h4>
          </div>
          <p data-aos="fade-up" data-aos-delay="100">
            Our lesson formatting is built on the highly effective <b>KBM Formula</b>, designed to enhance student engagement and ensure thorough understanding of each subject.
          </p>
          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>Starter Sheets:</b> Each lesson begins with a brief activity to activate prior knowledge and set the tone for the session, ensuring students are ready to dive into new material.
            </li>
            <li>
              <b>In-Class Work:</b> Using tailored lesson slides, interactive tasks and activities, students are guided through key concepts step-by-step. This approach, part of the KBM Formula, keeps learning engaging and accessible.
            </li>
            <li>
              <b>Exit Sheets:</b> At the end of each session, students complete an exit task to reinforce what they have learned and assess their understanding of the day’s content.
            </li>
            <li>
              <b>Exam Practice:</b> We incorporate regular exam-style questions to familiarise students with test formats and develop strong test-taking strategies, a core part of the KBM Formula for academic success.
            </li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Interactive Learning</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
           We incorporate interactive teaching like group discussions, activities, and videos to keep students engaged. This interactive approach encourages participation and keeps learning enjoyable and dynamic. 
          </p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Personalised Instruction</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Each student is different, so we adapt our teaching techniques based on individual learning styles and difficulties. This personalised support ensures that students get the specific help they need to succeed.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Practical Application</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We integrate real-life examples and problem-solving tasks during lessons, making learning more relevant and meaningful. This helps students understand how concepts apply beyond the classroom.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Regular Feedback</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We believe in providing consistent, constructive feedback on assignments and assessments. By highlighting strengths and areas for improvement, we help students track their progress and stay motivated.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Encouraging independent learning</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We aim to develop independent learning by teaching study skills and time management techniques. Our goal is to empower students to take responsibility for their studies, which not only helps them in the short term academically but immensely helps them in the long term personally.
          </p>


        </div>
      </div>
      </div>
  	
		</>
	)
}

export default TeachingMethod	