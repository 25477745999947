import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const PersonalisedLearningPlan = () => {
	return (
		<>

		<MetaData title="Personalised Learning Plans" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Personalised Learning Plans</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Personalised Learning Plans</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, we believe every child learns differently, and our Personalised Learning Plans are designed to address those differences. These customised plans ensure your child receives tailored support to reach their full academic potential.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Comprehensive Assessment</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We begin by conducting a thorough diagnostic assessment to understand where your child stands academically. This helps us identify their strengths, areas needing improvement, and their preferred learning style. With this information, we can create a plan that specifically targets their weaknesses.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Tailored Learning Objectives</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Based on the assessment, we set clear, achievable learning goals that reflect your child’s unique profile. These goals are both realistic and measurable, ensuring we can monitor progress and adjust the plan as needed. By setting personalised objectives, we ensure that each step in the learning process is meaningful and relevant to your child's success.
          </p>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Flexible Teaching Strategies</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Our teaching methods are designed to adapt to your child’s preferred learning style, whether they excel with visual aids, interactive activities, or focused one-on-one sessions. By personalising our approach, we make sure your child receives the kind of instruction that helps them stay engaged and motivated, turning challenges into accomplishments.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Ongoing Monitoring and Feedback</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We continuously monitor your child’s progress to ensure the personalised plan remains effective. Regular reviews and adjustments keep the plan aligned with your child’s evolving needs. We also provide you with frequent updates, so you’re always informed about your child’s growth and achievements.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Individualised Support</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Throughout their learning journey, your child will receive personalised support from our experienced tutors. We guide them through any academic difficulties, celebrate their successes, and help build their confidence. Our goal is to not only help them improve academically but also to encourage a love of learning.
At KBM Tutors, our Personalised Learning Plans are more than just academic support—they are a roadmap to helping your child unlock their full potential.
          </p>


        </div>
      </div>
      </div>
  	
		</>
	)
}

export default PersonalisedLearningPlan	