import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const PrivacyPolicy = () => {

	return (
		<>
  <MetaData title='Privacy Policy'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Privacy Policy</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Introduction</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
           At KBM Tutors, we care about your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and keep your data safe.
           </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Relevant legislation</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">Our Privacy Policy is designed to comply with relevant legislation, including the UK General Data Protection Regulation (UK GDPR). These regulations govern how we collect, use, store, and protect your personal information. We ensure that your data is handled in accordance with these laws, providing you with the right to access and correct your information. You can also request the deletion of your data. We maintain data protection at the highest standards and privacy in line with current legal requirements.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Information we collect</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We collect personal details such as your name, email address, phone number, and any other information you give us when you contact us or use our services. It helps us provide and improve our services, respond to your questions, and keep in touch with you.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">How we use your information</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">We use your personal information to:</p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>Send you updates and useful information about our services</li>
            <li>Communicate with you about your questions and bookings</li>
            <li>Enhance our website and services using your feedback
            </li>
            <li>Offer and manage our tutoring services</li>
            </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Data protection</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">We take steps to keep your personal data safe from unauthorized access or misuse. Only authorized staff can access your information.
</p>
<div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Cookies</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">Our website may use cookies, which are small files saved on your device, to help us understand how you use our site and make your experience better. You can turn off cookies in the settings of your browser if you prefer.
            </p>
           
          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Third-party services</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We do not share your personal information with others unless it is necessary to provide our services or required by law. Any third-party services we use must also keep your data safe.
            </p>
            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Your rights</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">You can ask us to view, correct, or delete your personal data. If you have any questions about this Privacy Policy or want to use your rights, please get in touch with us.</p>
            <p data-aos="fade-up" data-aos-delay="100"><b>Contact us</b><br></br>
For any questions and concerns about this Privacy Policy, please reach out to us at:
</p>
            <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Email:</b> info@kbmtutors.org</li>
            <li><b>Phone:</b> +44 020 8992 4506</li>
             </ul>

             <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Policy updates</h2>
          </div>

          <p>We may make updates to this Privacy Policy periodically, and all changes will be reflected on this page.</p>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Approved by</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            <i>Salman Khan, Director</i>
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><i>06th September 2024</i></p>



        </div>
      </div>
      </div>
		</>
	)
}

export default PrivacyPolicy