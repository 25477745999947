import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const CookiePolicy = () => {

	return (
		<>
  <MetaData title='Cookie Policy'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Cookie Policy</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Cookie Policy</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
          At KBM Tutors, we use cookies to make your experience on our website better. Cookies are small files. They are stored on your device, which helps us understand how you use our site and how we can improve it. Cookies are saved on your device when you visit any website. They help us remember your choices and make our site work better for you.
 </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">How we use cookies</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">We use cookies to:</p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Make the site work better:</b> Cookies help us remember your settings and preferences for a smoother experience.</li>
            <li><b>Understand how you use our Site:</b> Cookies allow us to see which parts of our site you visit most often so we can make improvements.</li>
            <li><b>Show you relevant content:</b> Cookies help us provide content and ads that match your interests.
            </li>
            </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Types of cookies we use</h2>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Essential cookies:</b> These are necessary for our site to work properly, like logging in or navigating pages.</li>
            <li><b>Performance cookies:</b> They help us understand how you use our website so we can make it better.</li>
            <li><b>Functionality cookies:</b> They remember your choices, like language or login info, to make your experience smoother.
            </li>
            <li><b>Targeting cookies:</b> They track what you look at to show you ads and content that might interest you.</li>
            </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Managing cookies</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">You can manage cookies by utilising your browser settings. Most browsers let you block or delete cookies, but this might affect how our site works.
</p>
<div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Changes to our cookie policy</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">We might update our Cookie Policy now and then, and we will post any changes here, so please check back regularly.</p>
           
          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Contact us</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            If you have questions about our Cookie Policy, email us at info@kbmtutors.org or call +44 020 8992 4506.</p>
            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Annual review</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">We review and update this policy every year.
</p>
            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Approved by</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            <i>Salman Khan, Director</i>
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><i>06th September 2024</i></p>



        </div>
      </div>
      </div>
		</>
	)
}

export default CookiePolicy