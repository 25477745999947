import React,{ useEffect, useState } from 'react';
import {Link,useLocation} from 'react-router-dom';

const Header = () => {

	const [isSticky, setIsSticky] = useState(false);

  const handleScroll = () => {
    const sticky = window.scrollY > 0;
    setIsSticky(sticky);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path ? 'active' : ''; // Determine if the link is active
  };

	return (
		<div>
		<div className="site-mobile-menu">
		    <div className="site-mobile-menu-header">
		      <div className="site-mobile-menu-close">
		        <span className="icofont-close js-menu-toggle"></span>
		      </div>
		    </div>
		    <div className="site-mobile-menu-body"></div>
		</div>


		  
		  <nav className="site-nav">
		    <div className="top-bar">
		      <div className="container-fluid">
		        <div className="row align-items-center">

		          <div className="col-6 col-lg-9">
		            {/*<Link to="#" className="small mr-3"><span className="icon-question-circle-o mr-2"></span> <span className="d-none d-lg-inline-block">Have a questions?</span></Link> */}
		            <Link to="#" className="small mr-3"><span className="icon-phone mr-2"></span> <span className="d-none d-lg-inline-block">020 8992 4506</span></Link> 
		            <Link to="#" className="small mr-3"><span className="icon-envelope mr-2"></span> <span className="d-none d-lg-inline-block">info@kbmtutors.org</span></Link> 
		          </div>

		          <div className="col-6 col-lg-3 text-right">
		            <Link to="/login" className="small mr-3">
		              <span className="icon-lock px-1"></span>
		              Log In
		            </Link>
		            <Link to="/register" className="small">
		              <span className="icon-person px-1"></span>
		              Register
		            </Link>
		          </div>

		        </div>
		      </div>
		    </div>
		    <div className={`sticky-nav js-sticky-header ${isSticky ? 'sticky' : ''}`}>
		      <div className="container-fluid position-relative">
		        <div className="site-navigation text-center">
		          <Link to="/" className="logo menu-absolute m-0"><img src="../images/update-logo.png" className="img-fluid" width="100" /></Link>

		          <ul className="js-clone-nav d-none d-lg-inline-block site-menu">
		            <li className={isActive('/')}><Link to="/">Home</Link></li>
		            <li className={`has-children ${isActive('/about')}`}>
		              <Link to="/about">About Us</Link>
		              <ul className="dropdown">
		                <li className={isActive('/vision')}><Link to="/vision">Misson & Vision</Link></li>
		                <li className={isActive('/kbm-formula')}><Link to="/kbm-formula">The KBM Formula</Link></li>
		                <li className={isActive('/ethos')}><Link to="/ethos">Ethos</Link></li>
		              </ul>
		            </li>
		            <li className={`has-children ${isActive('/tutoring-services')}`}>
		              <Link to="/tutoring-services">Tutoring Services</Link>
		              <ul className="dropdown">
		                <li className={isActive('/service/key-stage-2')}><Link to="/service/key-stage-2">Key Stage 2</Link></li>
		                <li className={isActive('/service/key-stage-3')}><Link to="/service/key-stage-3">Key Stage 3</Link></li>
		                <li className={isActive('/service/gcse')}><Link to="/service/gcse">GCSE</Link></li>
		                <li className={isActive('/service/11+-exam')}><Link to="/service/11+-exam">11+ Exams</Link></li>
		                <li className={isActive('/service/a-level')}><Link to="/service/a-level">A-level</Link></li>
		                <li className={isActive('/service/sat')}><Link to="/service/sat">SATs</Link></li>
		              </ul>
		            </li>
		            <li className={`has-children ${isActive('/our-tutors')}`}>
		              <Link to="/our-tutors">Our Tutors</Link>
		              <ul className="dropdown">
		                <li className={isActive('/tutors-qualification')}><Link to="/tutors-qualification">Tutors' Qualifications</Link></li>
		                <li className={isActive('/teaching-method')}><Link to="/teaching-method">Teaching Methods</Link></li>
		              </ul>
		            </li>
		            <li className="has-children">
		              <Link to="#">Why Choose Us</Link>
		              <ul className="dropdown">
		                <li className={isActive('/personalised-learning-plan')}><Link to="/personalised-learning-plan">Personalised Learning Plans</Link></li>
		                <li className={isActive('/flexible-scheduling')}><Link to="/flexible-scheduling">Flexible Scheduling</Link></li>
		                <li className={isActive('/progress-tracking')}><Link to="/progress-tracking">Progress Tracking</Link></li>
		              </ul>
		            </li>
		            <li className="has-children">
		              <Link to="#">Resources</Link>
		              <ul className="dropdown">
		                <li className={isActive('/blogs')}><Link to="/blogs">Blogs</Link></li>
		                <li className={isActive('/faq')}><Link to="/faq">FAQs</Link></li>
		              </ul>
		            </li>
		            <li className={`has-children ${isActive('/contact')}`}>
		              <Link to="/contact">Contact Us</Link>
		              <ul className="dropdown">
		                <li className={isActive('/contact')}><Link to="/contact">Contact Information</Link></li>
		                <li className={isActive('/book-consultation')}><Link to="/book-consultation">Book a Free Session</Link></li>
		              </ul>
		            </li>
		          </ul>

		          <Link to="#" className="btn-book btn btn-secondary btn-sm menu-absolute">Book Now</Link>

		          <Link to="#" className="burger ml-auto float-right site-menu-toggle js-menu-toggle d-inline-block d-lg-none light" data-toggle="collapse" data-target="#main-navbar">
		            <span></span>
		          </Link>

		        </div>
		      </div>
		    </div>
		  </nav>
		</div>
	)
}

export default Header