import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const EqualityPolicy = () => {

	return (
		<>
  <MetaData title='Equality and Diversity Policy'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Equality and Diversity Policy</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Equality and Diversity Policy</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At <b>KBM Tutors</b>, we are dedicated to promoting equality, respect, and inclusion for all learners, staff, and visitors. We ensure that everyone, irrespective of their background or personal circumstances, feels valued and supported. This policy outlines our commitment to fairness and how we follow the law to ensure equal opportunities for everyone.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Relevant legislation</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">We follow the rules set out in the following laws:</p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Equality Act 2010:</b> This law protects individuals from unfair treatment and promotes equality.</li>
            <li><b>Human Rights Act 1998:</b> This law ensures that everyone's rights to fairness, respect, and dignity are protected.</li>
            <li><b>Special Educational Needs and Disability Act 2001:</b> We are committed to providing necessary adjustments to support learners with disabilities.</li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Our commitments</h2>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Fair treatment:</b> We will not tolerate discrimination, harassment, or bullying based on characteristics like race, gender, age, disability, religion, or sexual orientation. Everyone has the right to equal treatment.</li>
            <li><b>Equal opportunities:</b> We aim to provide everyone with the same learning and employment opportunities. We will make reasonable adjustments to ensure learners with disabilities have full access to education and services.</li>
            <li><b>Respect for everyone:</b> We encourage a culture of kindness where all learners, staff, and visitors feel valued. We work to create a positive and supportive learning environment.</li>
            <li><b>Inclusive learning:</b> Our teaching methods and materials meet the needs of all learners, with extra support provided where necessary. Adjustments are made to assist those with specific learning requirements.</li>
            <li><b>Staff training:</b> We regularly train our staff on equality and diversity to ensure they understand their roles in promoting a fair, inclusive environment for everyone.</li>
          </ul>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Monitoring and improvement:</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We monitor our progress in equality and diversity through feedback from learners, staff, and stakeholders. Complaints are taken seriously and addressed promptly. We continuously review our practices to ensure they align with legal requirements and best practices.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Annual review</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We will review and update this policy annually to ensure its relevance and compliance with any changes in legislation.
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><b>Next review date:</b> 05th September 2025</p>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Approved by</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            <i>Salman Khan, Director</i>
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><i>05th September 2024</i></p>



        </div>
      </div>
      </div>
		</>
	)
}

export default EqualityPolicy