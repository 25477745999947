import React from 'react';
import {Link,useParams} from 'react-router-dom';
import MetaData from './MetaData.js';
import serviceData from '../serviceData.js';

const ServiceDetailPage = () => {

	const { name } = useParams();

	const service = serviceData[name] || {};

	return (
		<div>
			<MetaData title={service.title} />
			
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('../../images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">{service.title}</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section serviceSec">
        <div className="container">
          <div className="col-lg-12 mb-5 mb-lg-0">

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
              <h2 className="line-bottom mb-4">{service.title}</h2>
            </div>

            <div dangerouslySetInnerHTML={{ __html: service.description }}></div>

            {/*<p data-aos="fade-up" data-aos-delay="100">{service.description}</p>*/}

          </div>
        </div>
      </div>
		</div>
	)
}

export default ServiceDetailPage