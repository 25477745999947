const serviceData = {
    'key-stage-2': {
      title: 'Key Stage 2',
      description: 
      `
        <p>
          Our Key Stage 2 Tutoring is specifically designed to assist younger students, providing them with a strong foundation in their learning journey. At KBM, we use our tailored lessons, which include our KBM Formula, for your child’s success. We take one step at a time to tackle our students’ weaker topics to ensure they are ready for any question in their exams. 
        </p>
        <h2>Maths</h2>
        <p>
          Maths can be challenging for young learners, but our approach aims to make it accessible and enjoyable. Our Maths sessions focus on core skills such as number recognition, basic operations, and problem-solving techniques. We use hands-on activities and visual aids to ensure that learning is engaging and use our in-class sheets to solidify their knowledge. By breaking down challenging concepts, we help students develop a solid understanding of mathematical principles. If your child is facing difficulties with numeracy skills, our tailored Maths tutoring can provide the support they need to excel.
        </p>
        <h2>English</h2>
        <p>
          In our English classes, we place a strong emphasis on developing reading, writing, and comprehension skills. We understand that younger students often benefit from engaging and interactive methods. Therefore, we incorporate enjoyable and effective activities such as collective feedback, speech and creative writing exercises. Our tutors break down complex lessons into smaller, manageable parts, which helps students grasp key concepts without feeling overwhelmed. Whether your child is struggling with reading fluency, vocabulary, or grammar, our English tutoring is designed to build their confidence and love the subject.
        </p>
        <h2>Science</h2>
        <p>
          In Science, we aim to spark curiosity and encourage exploration. Our lessons cover a range of topics, including biology, chemistry, and physics, using interactive lesson formatting and engaging discussions to make learning fun. We believe having an interest in science at this age is key for high school success, so our goal is to instil a sense of wonder about the natural world and make complex ideas easy to understand. Our students benefit greatly from our specific lesson structure in science. We truly try to make that difference.
        </p>
      `,
    },
    'key-stage-3': {
      title: 'Key Stage 3',
      description: 
      `
        <p>
         Our Key Stage 3 Tutoring is designed to support students in Years 7, 8, and 9 as they transition into secondary school. This phase is critical as students encounter more challenging subjects and have a faster pace of learning. At KBM, we aim to make this transition smoother by offering personalised tutoring in core subjects, including English, Mathematics, and Science. Our goal is to help students build a solid foundation of knowledge and skills that will prepare them for future academic success.
         </p>
        <h2>English</h2>
        <p>
         In Key Stage 3, English focuses on enhancing students’ analytical and critical thinking skills. Our tutoring sessions go beyond basic reading and writing to cover more advanced areas such as textual analysis, literary techniques, and essay writing. We teach students how to interpret complex texts, including novels, poetry, and plays while guiding them in structuring well-argued essays. Our tutors focus on developing critical reading skills and improving vocabulary and grammar, ensuring students are prepared for higher-level English work. We also help them refine their ability to express ideas clearly and persuasively in written and verbal presentations.
         </p>
        <h2>Maths</h2>
        <p>
          Mathematics at Key Stage 3 introduces more advanced topics that lay the groundwork for GCSEs. Our Maths tutoring covers key areas such as algebra, geometry, trigonometry, and probability. We help students understand more complex equations, solve problems involving ratios and proportions, and apply geometric reasoning to solve real-world problems. Our tutors break down difficult topics like quadratic and simultaneous equations into manageable steps, ensuring students grasp these concepts confidently. We also develop their logical thinking and problem-solving skills, essential to answering the longer multistep exam questions.
          </p>
        <h2>Science</h2>
        <p>
         In Science, students explore topics in biology, chemistry, and physics at a deeper level. Our science tutoring sessions are designed to make these complex subjects more accessible, as students will be more unfamiliar with this subject than others. We cover key areas such as cellular biology, chemical reactions, atomic structure, and forces and energy in physics. We focus on helping students develop a strong understanding of scientific principles while teaching them how to apply this knowledge to solve problems. Our tutors also emphasise scientific investigation skills, such as forming hypotheses and interpreting data. Our exam practice prepares them in exam conditions to help them ace their exams.
      </p>
      `,
    },
    'gcse': {
      title: 'GCSE',
      description: 
      `
       <p>
         Our GCSE Tutoring programme is specifically designed for students in Years 10 and 11, focusing on thorough preparation for the crucial GCSE exams. As these exams play a significant role in shaping future education and career paths, we aim to provide students with the skills and knowledge necessary for success. Our tutoring covers key subjects, including English, Mathematics, and Science which are the basis for success in all other subjects. We also incorporate extensive exam preparation, including past paper practice and effective study techniques, within each subject.
         </p>
        <h2>English</h2>
        <p>
         For GCSE English, we focus on harder topics such as literary analysis, essay writing and understanding complex texts, including Shakespearean plays, novels and poetry. Our tutors guide students through how to approach different types of questions, from analysing themes and characters to identifying and interpreting literary techniques. We use past exam papers to give students the experience of real exam scenarios, helping them become familiar with common question types and the level of depth required in their responses. Additionally, we teach effective time management techniques for essay-based exams, ensuring students can construct thoughtful, well-structured answers within the exam time limit.
        </p>
        <h2>Maths</h2>
        <p>
          GCSE Mathematics introduces more complex topics such as circle theorems, bearings, probability, and statistics. Our Maths tutoring sessions are designed to simplify difficult concepts like quadratic equations, simultaneous equations, and geometric reasoning. We focus on helping students develop problem-solving skills that are crucial for tackling challenging exam questions. To prepare students for their GCSE exams, we integrate past paper practice, teaching them how to apply their knowledge to real exam scenarios. Our tutors offer specific tips for managing time effectively during the exam, such as prioritising easier questions first and using shortcuts for complex calculations, ensuring students can maximise their performance within the time limit.
          </p>
        <h2>Science</h2>
        <p>
         In GCSE Science, students must master a range of topics across biology, chemistry and physics. Our tutoring sessions cover advanced areas such as genetics and ecosystems in biology, chemical bonding and reaction rates in chemistry, and energy transfer and electromagnetism in physics. We use a hands-on approach to make these subjects more accessible, while also providing students with extensive past paper practice. This familiarises them with the types of questions they will face in the exams, particularly data interpretation and extended response questions. We focus on improving exam techniques such as structuring multi-step questions, interpreting graphs and tables. 
        </p>
        <h2>Integrated Exam Preparation</h2>
        <p>
         At KBM Tutors we know how important past paper practice is hence throughout our GCSE tutoring programme, it is woven into each subject. By regularly practicing with past papers, students gain a solid understanding of how to approach exam questions, apply subject knowledge effectively, and manage their time under exam conditions. Our tutors provide personalised feedback on students’ performance in practice exams, helping them to identify areas for improvement and develop strategies to overcome any weaknesses. This consistent focus on exam readiness ensures students feel fully equipped to face their GCSE exams with confidence and achieve their best possible results.
        </p>
      
      `,
    },
    'a-level': {
      title: 'A-Level',
      description: 
      `
        <p>
         Our A-Level Tutoring programme is designed to support students through one of the most challenging periods of their academic journey. We understand how difficult and overwhelming A-Levels can be, with the increased workload and pressure to perform well in these critical exams. At KBM Tutor, we aim to make the process easier by breaking down complex topics, offering tailored support, and helping students manage their time effectively. Our goal is to ensure students are well-prepared for their A-Level exams and feel confident in achieving the best possible results, setting them up for future success in higher education.
         </p>
        <h2>Mathematics</h2>
        <p>
         A-Level Mathematics builds on foundational concepts and introduces more complex topics such as calculus, advanced trigonometry, and statistical analysis. Our Maths tutoring simplifies these advanced areas by breaking them down into manageable steps, ensuring students fully grasp the underlying principles. We also focus on problem-solving techniques, helping students apply their knowledge to tackle difficult exam questions. Past paper practice is integral to our approach, giving students hands-on experience with real exam scenarios. Our tutors provide time-saving techniques for solving problems under pressure, equipping students with the skills they need to perform confidently on exam day.
         </p>
        <h2>Science</h2>
        <p>
          In A-Level Science, students encounter in-depth material in subjects like biology, chemistry, and physics. Our Science tutoring focuses on simplifying difficult concepts such as organic chemistry mechanisms, genetic engineering, or electromagnetic induction, depending on the subject. We guide students through practical experiments, data analysis, and theoretical understanding, ensuring they are fully prepared for both written exams and practical assessments. By incorporating extensive past paper practice, we ensure students are familiar with the types of questions they will face, including complex data interpretation and long-answer questions. Our tutors also offer strategies for approaching these questions systematically, helping students maximise their marks.
          </p>
      `,
    },
    '11+-exam': {
      title: '11+ Exam',
      description: 
      `
        <p>
         Our 11+ Tutoring programme is designed to help younger students excel in their preparation for the 11+ entrance exams. We understand that this exam is different from regular academic assessments, as it tests a broad range of skills that go beyond standard school subjects. For many students, the 11+ can be their first experience with competitive exams, and it plays a crucial role in securing a place at their preferred school. At KBM Tutors, we believe that the key to success in the 11+ is a combination of consistent practice, tailored support, and the right strategies to tackle the unique challenges of the exam.
         </p>
        <h2>English</h2>
        <p>
         In the English section, students are tested on their reading comprehension, vocabulary, grammar, and writing abilities. Our tutoring sessions focus on enhancing students' understanding of complex texts, improving their ability to analyse and interpret information, and expanding their vocabulary. We also provide structured practice in creative and descriptive writing, helping students develop clear, well-organised responses to exam prompts. Our tutors simplify the question formats and introduce students to typical exam scenarios, using past paper questions to build familiarity and ease with the kinds of questions they will face. By combining practice with constructive feedback, we ensure that students are fully prepared for the English component of the 11+ exam.
         </p>
        <h2>Mathematics</h2>
        <p>
          The Maths exam requires students to demonstrate strong problem-solving skills and a solid understanding of topics such as arithmetic, geometry, and algebra. Our tutoring focuses on breaking down complex mathematical concepts into simpler steps, making them more accessible to younger learners. We work with students on mastering mental arithmetic, number patterns, and key reasoning skills, which are all essential for the 11+ exam. Regular timed exercises and past paper practice help students improve their speed and accuracy while learning techniques to approach tricky problem-solving questions. Our goal is to ensure students feel confident in applying their skills under exam conditions, helping them to achieve the best possible results.
          </p>
          <h2>Verbal Reasoning</h2>
        <p>
          Verbal Reasoning is a unique aspect of the 11+ that tests a student's ability to think logically, process language, and solve word-based puzzles. Our tutors focus on developing the reasoning skills needed to understand patterns, sequences and relationships between words and concepts. We guide students through the different types of verbal reasoning questions, including anagrams, synonyms and logical deductions, ensuring they are familiar with the range of questions that can appear in the exam. By practising regularly with a variety of question formats, students improve their reasoning abilities and learn how to approach each question confidently. Timed practice sessions help build speed and efficiency, which are key to performing well in this section of the exam.
          </p>
          <h2>Non-Verbal Reasoning</h2>
        <p>
          Non-verbal reasoning tests a student’s ability to solve problems using visual information, such as shapes, patterns, and sequences. This section requires logical thinking and the ability to identify relationships between visual elements. Our tutoring sessions help students develop strategies for quickly interpreting and solving these visual puzzles. We introduce them to the different types of non-verbal reasoning questions, such as identifying similarities and differences, completing sequences, and recognising mirror images. We ensure that students become confident in this section through repeated practice and hands-on exercises.
          </p>
      `,
    },
    'sat': {
      title: 'SATs',
      description: 
      `
        <p>
         Our SATs Tutoring programme is designed with specific help for younger students in mind, ensuring that our approach is engaging and accessible for children in Key Stage 2. We understand that SATs can be a daunting experience for students at this age, so we tailor our lessons to make learning fun and less overwhelming. Our tutors use creative teaching techniques, breaking down complex concepts into simple, manageable parts to build confidence and reduce exam-related stress. The goal is to help students feel fully prepared for their SATs and excited to take the next steps in their academic journey.
         </p>
        <h2>English</h2>
        <p>
         For English, we focus on developing key skills in reading, writing and comprehension. Our tutors help students strengthen their understanding of grammar, punctuation, spelling, and vocabulary, all of which are crucial components of the SATs. We use simple demonstrations, engaging activities, and relatable examples to make these concepts easier for younger learners to grasp. Additionally, we introduce students to the different types of reading comprehension questions they will encounter on the exam, ensuring they are comfortable with various question formats and can respond accurately. By practising with past papers and sample questions, students become familiar with the exam layout, helping them build confidence and improve their performance.
         </p>
        <h2>Mathematics</h2>
        <p>
          In Maths, we cover all essential areas, such as arithmetic, problem-solving, and reasoning. Our tutoring sessions are designed to make Maths enjoyable and less intimidating for younger learners. We simplify complex topics such as fractions, percentages, and word problems, using clear, step-by-step explanations and practical examples. Through regular practice, students develop a solid understanding of core mathematical concepts and how to apply them in different scenarios. We also introduce timed exercises and past paper questions integrated using our KBM Formula to help students get used to the pace of the exam and improve their time management skills. 
          </p>
          
      `,
    },
  };
export default serviceData;