const categoryData = {
    'tailored-lessons': {
      title: 'Tailored Lessons',
      description: 
      `
        At KBM Tutors, we offer Tailored lessons to help students get the most out of their tuition. Our approach is all about customising lessons to meet each student's unique needs. We know that every student learns differently, so we work with them to make sure they get the right support.
        Our tutors in London start by finding out what each student needs help with. Whether they're in Key Stage 2, Key Stage 3, Key Stage 4, preparing for GCSE, A-Level, or the 11 Plus Exam, we create a special plan just for them. If your child needs a math tutor, an English tutor, or help with GCSE Science, our private tutors are here to help.
        For younger students in Key Stage 2, we make learning fun and easy. As they move to Key Stage 3 and Key Stage 4, our home tutors focus on specific subjects to get them ready for their exams. For students getting ready for A-Level exams or the 11 Plus Exam, we provide targeted help, including online tutoring if needed.
        We also help with 11 Plus exam papers and offer support for subjects like Physics and Math. Our A-level tutors make sure students understand what they need to know and feel confident about their studies.
        Our Tailored lessons are flexible and change as students' needs change. We use different teaching methods to keep lessons interesting and make sure students stay engaged.
        KBM Tutors provides personalised tuition with tailored lessons for students in Key Stage 2, Key Stage 3, Key Stage 4, GCSE, A-Level, and those preparing for the 11 Plus Exam. Whether you need a math tutor, an English tutor, or help with GCSE Science, our tutors offer the support needed to achieve academic success.

      `,
    },
    'flexible-scheduling': {
      title: 'Flexible Scheduling',
      description: 
      `
        At KBM Tutors, we know that students have busy lives. Our flexible schedules make it easy for them. So, our tutoring fit into their routines. Whether you need a home tutor or you want online tutoring, we can work with your schedule.
Our tutors in London offer flexible times for lessons so students can get the help they need without stress. This is especially helpful in preparing for important exams like GCSE, A-Level, or the 11 Plus exam. We recognise that students may have other commitments, such as schoolwork or extracurricular activities. So, we make sure our sessions fit into their lives.
If your child needs a math tutor or an English tutor, we can arrange sessions that work around their other responsibilities. For example, if they are busy during the week, we can schedule lessons in the evenings or on weekends. This flexibility helps your child stay on track.
Also, our flexible scheduling is ideal for students who need regular support in subjects like GCSE science or physics maths. By offering a range of times for tutoring, we ensure that students receive consistent and practical help tailored to their needs. It is crucial to stay on top of their studies and prepare thoroughly for exams.
For those preparing for the 11 Plus exam, our flexible scheduling allows for focused practice and review at the most convenient times. It helps students to stay organised and confident. Our private tutors are here to provide the right help at the right time, ensuring that students feel prepared and supported.
Our flexible scheduling helps students balance their tutoring with other parts of their lives. Whether you're looking for a math tutor, English tutor, or support for exams like GCSE or A-Level, we make it easy to fit high-quality tuition into your busy schedule.

      `,
    },
    'advanced-tracking': {
      title: 'Advanced Tracking',
      description: 
      `
        We at KBM Tutors use advanced tracking to ensure that no student misses any important help. Our state-of-the-art tool enables us to keep track of every student's progress to guarantee that none of them goes off the track.
Our tutors use advanced systems that allow them to follow their students' schoolwork. Whether it is GCSEs, A-Levels, or the 11 Plus exam, the tools help us get performance reports to know exactly where they are doing well and where they might need a little extra help.
With such advanced tracking for learners working on subjects like Science, math, and English at the GCSE level, we are able to adjust our approaches to teaching around their needs. We can immediately and seamlessly change our approaches if we notice challenges in some topics, and hence save the student time and pave the way for offering sufficient support. This way, there is no room for letting a student fall behind; each gets all the support they deserve in their own way.
Furthermore, we are able to determine students who might need additional help in things like physics, maths tutoring or topics that have been complex for them to absorb through our tracking systems. Through periodic reviews of their progress, we're able to put resources into focused areas where students might need more practice to build confidence and skill.
The enhanced monitoring also offers easy planning and measurement of the progress of students preparing for high-stakes exams, much of which includes the 11 Plus exam. We can monitor performances over time, set goals, and make readjustments towards ensuring that our students are fully prepared for the test.
It is different at our centres, as our advanced tracking systems are a key element to effective tuition. They help us keep track of everything about the student. We make decisions based on the data we gather. This, in essence, brings about the personalization of every student. The ultimate goal is to have each student achieve their academic targets.

      `,
    },
    'interactive-learning': {
      title: 'Interactive Learning',
      description: 
      `
        In KBM Tutors, learning is meant to be active and enjoyable. This working method guarantees a high level of interest in students' lessons and engages them in their studies interactively. Instead of listening passively to a tutor, activity-based involvement of students in their own learning is through different activities and exercises.
Our tutors make lessons very interactive by the use of many methods. For instance, in the course of math tutoring, the students are involved in solving problems using hands-on tools or digital tools. In turn, during tutoring in English, they may act in regard to some stories or take part in a very vibrant discussion around some topics. All this activity will help currently taught students realise and memorise far better.
This type of interactive learning goes a long way to assist the student in revision for important examinations, like the GCSEs or the 11 Plus examination. In this way, making the lessons more interactive will help students to become more attentive and interested. They are more likely to enjoy learning and feel excited about their progress.
Such lessons make complicated concepts appear so simple in subjects like GCSE science or physics maths. Students, in an interactive lesson, will ask questions on the subject they don't get, go into detail on all various topics, and are able to work out any problems they have with the help of their tutor. This approach helps in building students confidence and ensures that whatever is taught in class is well grasped.
Most importantly, interactive learning makes tutoring sessions considerably more effective and, therefore, quite fun to the learner. Interactive learning techniques guarantee your child's participation in the learning process, notably, in the learning of math, English, or test preparation.

      `,
    },
    'goal-setting': {
      title: 'Goal Setting',
      description: 
      `
        At KBM Tutors, we understand that practical goal setting is a critical success factor and, therefore, has to be done correctly. The method we use to guide students in setting goals makes our students remain encouraged throughout their learning period, whichever level they are: GCSE, A-Level or the 11 Plus exam levels.
First, we engage the students in a process of finding out their academic objectives. This may be in the area of academics, skills development or even performance on a test or an exam. When these are set, the students are in a position to know what they are to achieve.
Our tutors in London then assist in dividing them into smaller achievable objectives which the student can accommodate. This process also helps the students to be more organised so that they can be able to make appropriate adjustments to cover the rest of the semester. For example if a particular student wants to improve grades in Mathematics or English we put achievable goals to complete in one week or a month so that he or she will be well prepared and will at least have covered everything needed.
Such goals are reviewed from time to time, and this helps motivate the students. They can tell how much progress they have made, and they can change their study schedules if they have to. Specific goals assist the students, especially when preparing for central tests, say GCSE Science or physics maths, by targeting the most crucial areas to study since time is valuable.
Also, it should be stated that goals help to develop such positive personal qualities as self-discipline and proper time management. While the students are pursuing their targets, they increase their organisational skills as well as time management skills. It is quite useful not only in the educational process but also in further life and career paths as well. Our objective is for the students to be active participants in the learning process, develop confidence, and maximise outcomes.

      `,
    },
    'study-skills': {
      title: 'Study Skills',
      description: 
      `
        KBM Tutors help the children develop better study habits that assist them in their learning. The study skills are among the important aspects of learning that are not only a matter of what but also how to study.
One has to ensure that learners find out how they can easily manage the little time they have and avoid unnecessary things. For example, we explain and demonstrate how they can develop a study timetable. So they can divide the work into smaller sections. This way, they do not end up feeling very much pressure, and they can deal with things one at a time.
We also assist students to develop techniques on how to take useful notes. So it goes without saying that good notes can make studying for exams such as the GCSEs or A-Levels much easier to handle. They are taught how to note down something then reading through to make it easier to remember.
A third skill is the ability to understand how to prepare for tests. As for the approaches used to help students prepare for examinations, we offer them the tips on how to study, practice and be calm. This makes them feel more confident to take their tests since they have the notes in a format that will make understanding easier.
In particular, these study skills are useful for students who work on a subject such as mathematics, English, or preparing to pass the 11 Plus exam. These enable the students to understand their studies well, benefit from it and perform well in their examinations.
If they are taught good study skills, then the students must be smarter and capable of learning more on their own. They discover how to schedule themselves, organise themselves and how to learn, hence enhancing their learning process to be more successful as well as enjoyable.

      `,
    },
    'parental-involvement': {
      title: 'Parental Involvement',
      description: 
      `
        At KBM Tutors, we respect the fact that parents have an important part to play in a child's academic process. The approach we use for parents' engagement assists in providing a supportive framework for students, hence enhancing success and enjoyment while learning.
We strongly support parents' involvement in their child's educational process. This begins with the provision of regular contact between parents and tutors on the child. Periodically, parents receive bulletins containing information about their child's progress, skills, skills and behaviors they have exhibited which need attention. This way, the parents are always informed of how their child is doing and can always offer support at home.
KBM also provide information and recommendations for case parents, that can assist in encouraging child's learning if not in during tutoring process. For instance, we may offer hints on how one can establish a proper study space at home or recommend whats the best study equipment to use or how to study effectively. This assists the parents to know how they can support their child in subjects such as math, English or even in preparation for big tests like GCSEs or even the 11 Plus test.
It also encompass use of parental participation in relation to their views and opinions as well. On our part, we conduct ourselves mutuality with parents by handling issues their child might find hard to deal with. This helps students to be supported at home and also be supported in their tutored lessons by different persons.
By encouraging the parental engagement, we ensure that parental involvement is effective and contributes to the promotion of cooperative learning. Apart from increasing students' confidence and motivation, it enables the achievenent of academic targets in a more efficient way.

      `,
    },
    'personalised-support': {
      title: 'Personalised Support',
      description: 
      `
        KBM Tutors understand and accept the fact that every learner is different. That is why we provide options to ensure that every learner receives assistance they require to succeed.
Personalisation means, and it has been earlier mentioned, that teachers must adapt to the required teaching strategies that will be perfect for every single learner. When a student joins us, we need to identify if they are strong at a particular task or if they need additional attention. This way, we are in a position to know how they learn and what kind of help is appropriate for them.
For instance, if a student has a difficulty in performing mathematics or requires preparations for GCSE, then classes will be tailored to their academic difficulties. If a child requires additional tutoring in algebra, or if a child requires additional support in English, the tutors develop the teaching programs to meet each child's requirements.
This also depends on the manner in which the student is performing in their classwork. We change the students' support plan to cover each area that requires their attention, including where there is progress and where more help is needed. The flexibility of the system is therefore beneficial in the sense that it guarantees the student the appropriate support whenever.
Another dimension of individualised assistance refers to encouragement and self-confidence. Regarding the students and the proper goal setting and celebration of their accomplishments, our tutors do this with the learners. This, in turn, increases their morale and keeps them indoors studying.
It is about recognising and meeting each student's needs so as to assist him to the best of one's ability. It enhances learning and teaching processes, making it easier for every learner to succeed and achieve his or her goals.

      `,
    },
  };
export default categoryData;