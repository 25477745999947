import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Tutors = () => {

	return (
		<>
  <MetaData title='Our Tutors' />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Our Tutors</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Our Tutors</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            About KBM Tutors: KBM Tutors is a team of qualified tutors with a passion towards their work. For each of the tutors here, we have ensured they are qualified, experienced in teaching the students and are able to understand the learners. Our tutors do not only teach but also assist and encourage the student to work to the best of their ability.
This individualisation of learning makes it possible for the students to be given a personalised learning technique which suits them. If your child requires remedial help, average performance or even advanced learning, our tutors give the needed direction for success.
We provide tuition in subjects at Key Stage 2, Key Stage 3, Key Stage 4, GCSE, A-Level and in preparations for exams for instance SATs and 11+. KBM's tutors are experts who will teach your child in areas of strength to ensure the child gets the requisite tutoring in areas of concern.
Our commitment is not limited to the period of our meeting sessions. We keep parents informed of their progress or re-evaluation from time to time so that they are in a position to ascertain the progress of their child. Such an approach assists in getting the most effective learning results for each learner.
Our lessons are intended to be fun and informative and include role playing, examples or case scenarios and other activities. It assists students to secure mastery of contents with ease hence improving their confidence and performances.
As for the students who have to take exams, our tutors concentrate on such aspects of learning as examinations tactics, how to revise, and time organisation. We assist the learners feel confident and prepared to handle exam exercises with increased efficiency.
</p>



        </div>
      </div>
      </div>
  


		</>
	)
}

export default Tutors