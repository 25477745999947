import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Contact = () => {

	return (
		<>
  <MetaData title='Contact Us'/>
			<div className="untree_co-hero overlay" style={{ backgroundImage: `url('images/4.png')` }}>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="col-lg-6 text-center ">
              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Contact Us</h1>
              <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200">
                <p>Feel free to reach out with any questions or to discuss how we can support your child's learning journey.</p>
              </div>

              {/*<p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>*/}

            </div>


          </div>

        </div>

      </div> 
    </div> 

  </div> 




  <div className="untree_co-section">
    <div className="container">

      <div className="row mb-5">
        <div className="col-lg-6 mb-5 order-2 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
          <div className="contact-info">

            <div className="address">
              <i className="icon-room"></i>
              <h4 className="mb-2">Location:</h4>
              <p>1 Concord Business Centre, Concord Road, London , W3 0TJ</p>
            </div>

            <div className="open-hours mt-4">
              <i className="icon-clock-o"></i>
              <h4 className="mb-2">Open Hours:</h4>
              <p>
                Monday-Friday:
                09:00 AM - 09:00 PM
              </p>
              <p>
                Saturday-Sunday:
                09:00 AM - 06:00 PM
              </p>
            </div>

            <div className="email mt-4">
              <i className="icon-envelope"></i>
              <h4 className="mb-2">Email:</h4>
              <p>info@kbmtutors.org</p>
            </div>

            <div className="phone mt-4">
              <i className="icon-phone"></i>
              <h4 className="mb-2">Call:</h4>
              <p>+44 020 8992 4506</p>
            </div>

          </div>
        </div>
        <div className="col-lg-6 mr-auto order-1" data-aos="fade-up" data-aos-delay="200">
          <form action="#">
            <div className="row">
              <div className="col-6 mb-3">
                <input type="text" className="form-control" placeholder="Your Name"/>
              </div>
              <div className="col-6 mb-3">
                <input type="email" className="form-control" placeholder="Your Email"/>
              </div>
              <div className="col-12 mb-3">
                <input type="text" className="form-control" placeholder="Subject"/>
              </div>
              <div className="col-12 mb-3">
                <textarea name="" id="" cols="30" rows="7" className="form-control" placeholder="Message"></textarea>
              </div>

              <div className="col-12">
                <input type="submit" value="Send Message" className="btn btn-primary"/>
              </div>
            </div>
          </form>
        </div>
      </div>

      
    </div>
  </div>
		</>
	)
}

export default Contact