import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Ethos = () => {

	return (
		<>
  <MetaData title='Ethos' />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Ethos</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Ethos</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            KBM Tutors' success comes from our special teaching methods across London – The KBM Formula. Our tutoring stands out from others by our unique lesson formatting and thorough the emphasis we put on practice to support individualised growth of our students. Furthermore, our unique office layout for students creates a professional environment for our students and means we have greater access to exceptional teaching resources, including newer technology, comprehensive learning materials, and our student portal.
          </p>
            <p data-aos="fade-up" data-aos-delay="100">
              Since our founding under KBM, we have developed a warm and welcoming atmosphere. This nurturing environment encourages students to reach their highest academic potential while also developing self-confidence, interpersonal skills and a love for learning. We believe that education is more than just academic achievement—it is about progressing everyone’s personal growth and resilience. We are fully committed to our mission, which has been central to KBM Tutors from the very beginning, our integrated approach helps us thrive with all our students, putting equal and maximum effort from all our tutors and lesson planning.
              </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 mb-5 mb-lg-0" data-aos="fade-up" data-aos-delay="0">

          <figure className="">
                <img src="images/Ethos.png" alt="Image" className="img-fluid" />
              </figure>

        </div>
        </div>
        <div className="col-lg-12">
          <p data-aos="fade-up" data-aos-delay="100">
            Our dedication to our mission is evident in our quality, personalised support and continuous growth as a trusted educational partner as outlined below:
          </p>
          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              Our approach is built on a natural passion for education. We strive to create an environment that nurtures every student's potential. 🡪 We believe our genuine passion for personal growth and education make a substantial difference when we tutor our students, focusing on more aspects than just grades.
            </li>
          </ul>
        </div>
      </div>
      </div>
  


		</>
	)
}

export default Ethos