import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';


const TutorService = () => {
	return (
		<>
			<MetaData title="Tutoring Services"/>

		  <div className="untree_co-hero overlay" style={{ backgroundImage: `url('images/4.png')` }}>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="col-lg-6 text-center ">
              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Tutoring Services</h1>
              <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200">
                <p>Another free template by Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live.</p>
              </div>

              <p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>

            </div>


          </div>

        </div>

      </div> 
    </div> 

  </div>


		  <div className="untree_co-section pb-0">
		    <div className="container"> 
		      <div className="row justify-content-center mb-5">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">Expert Tutoring for Every Academic Stage</h2>
		          <p>We provide personalised tutoring to help your child through every step of their learning experience and ensure they utilise their full potential.</p>
		        </div>
		      </div>
		      <div className="row">
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		          <div className="feature">
		            <span className="uil uil-brain"></span>
		            <h3>Key Stage 2</h3>
		            <p>With Key Stage 2 Tutoring, we help your child build a strong foundation with clear lessons and individual support.<Link to="/service/key-stage-2">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
		          <div className="feature">
		            <span className="uil uil-calculator-alt"></span>
		            <h3>Key Stage 3</h3>
		            <p>Key Stage 3 Tutoring boosts your child’s confidence and learning with simple, easy lessons tailored to their needs.<Link to="/service/key-stage-3">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
		          <div className="feature">
		            <span className="uil uil-book-open"></span>
		            <h3>Key Stage 4</h3>
		            <p>Key Stage 4 Tutoring prepares your child for important exams with clear guidance that helps them understand key concepts.<Link to="/service/key-stage-4">Read More</Link></p>
		          </div>
		        </div>


		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		          <div className="feature">
		            <span className="uil uil-book-alt"></span>
		            <h3>GCSE & A-Level</h3>
		            <p>GCSE & A-Level Tutoring assists your child through these crucial stages with lessons that simplify complex subjects.<Link to="/service/gcse-&-a-level">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
		          <div className="feature">
		            <span className="uil uil-history"></span>
		            <h3>11+ Exam</h3>
		            <p>11+ Exam Preparation prepares your child with focused support, making studying easier and building their confidence.<Link to="/service/11+-exam">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
		          <div className="feature">
		            <span className="uil uil-headphones"></span>
		            <h3>SATs</h3>
		            <p>SATs Preparation helps your child achieve great results with targeted tutoring, making learning and test prep straightforward.<Link to="/service/sat">Read More</Link></p>
		          </div>
		        </div>
		      </div>
		    </div> 
		  </div> 
		</>
	)
}

export default TutorService