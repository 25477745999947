import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Blog = () => {
	return (
		<>
			<MetaData title="Blogs" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Blogs</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section bg-light">
    <div className="container">
      <div className="row align-items-stretch">
        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="100">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-4-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Education for Tomorrow's Leaders</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="200">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-3-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Enroll Your Kids This Summer to get 30% off</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="100">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-2-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Education for Tomorrow's Leaders</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="200">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-1-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Enroll Your Kids This Summer to get 30% off</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>


        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="100">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-5-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Education for Tomorrow's Leaders</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4"  data-aos="fade-up" data-aos-delay="200">
          <div className="media-h d-flex h-100">
            <figure>
              <img src="images/img-school-6-min.jpg" alt="Image"/>
            </figure>
            <div className="media-h-body">
              <h2 className="mb-3"><Link to="#">Enroll Your Kids This Summer to get 30% off</Link></h2>
              <div className="meta "><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Untree.co</div>
              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
            </div>
          </div>
        </div>

      </div>

      <div className="row mt-5">
        <div className="col-12 text-center">
          <ul className="list-unstyled custom-pagination">
            <li><Link to="#">1</Link></li>
            <li className="active"><Link to="#">2</Link></li>
            <li><Link to="#">3</Link></li>
            <li><Link to="#">4</Link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
		</>
	)
}

export default Blog