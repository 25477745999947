import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Home = () => {
	return (
		<>
			
		 <MetaData title="KBM Tutor's"/>

		  <div className="untree_co-hero overlay" style={{ backgroundImage: `url('images/1.png')` }}>


		    <div className="container">
		      <div className="row align-items-center justify-content-center">

		        <div className="col-12">

		          <div className="row justify-content-center ">

		            <div className="col-lg-6 text-center ">
		              {/*<Link to="https://vimeo.com/342333493" data-fancybox data-aos="fade-up" data-aos-delay="0" className="caption mb-4 d-inline-block">Watch the video</Link>*/}

		              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Education is the Mother of Leadership</h1>
		              {/*<p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>*/}

		            </div>


		          </div>

		        </div>

		      </div> 
		    </div> 

		  </div> 


		  <div className="untree_co-section pb-0">
		    <div className="container"> 
		      <div className="row justify-content-center mb-5">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">Expert Tutoring for Every Academic Stage</h2>
		          <p>We provide personalised tutoring to help your child through every step of their learning experience and ensure they utilise their full potential.</p>
		        </div>
		      </div>
		      <div className="row">
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		          <div className="feature">
		            <span className="uil uil-book-open"></span>
		            <h3>Key Stage 2</h3>
		            <p>With Key Stage 2 Tutoring, we help your child build a strong foundation with clear lessons and individual support.<Link to="/service/key-stage-2">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
		          <div className="feature">
		            <span className="uil uil-book-alt"></span>
		            <h3>Key Stage 3</h3>
		            <p>Key Stage 3 Tutoring boosts your child’s confidence and learning with simple, easy lessons tailored to their needs.<Link to="/service/key-stage-3">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
		          <div className="feature">
		            <span className="uil uil-book-open"></span>
		            <h3>GCSE</h3>
		            <p>GCSE Tutoring prepares your child for important exams with clear guidance that helps them understand key concepts.<Link to="/service/key-stage-4">Read More</Link></p>
		          </div>
		        </div>


		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		          <div className="feature">
		            <span className="uil uil-book-alt"></span>
		            <h3>A-Level</h3>
		            <p>A-Level Tutoring assists your child through these crucial stages with lessons that simplify complex subjects.<Link to="/service/gcse-&-a-level">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
		          <div className="feature">
		            <span className="uil uil-book-open"></span>
		            <h3>11+ Exam</h3>
		            <p>11+ Exam Preparation prepares your child with focused support, making studying easier and building their confidence.<Link to="/service/11+-exam">Read More</Link></p>
		          </div>
		        </div>
		        <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
		          <div className="feature">
		            <span className="uil uil-book-alt"></span>
		            <h3>SATs</h3>
		            <p>SATs Preparation helps your child achieve great results with targeted tutoring, making learning and test prep straightforward.<Link to="/service/sat">Read More</Link></p>
		          </div>
		        </div>
		      </div>
		    </div> 
		  </div> 


		  <div className="untree_co-section">
		    <div className="container">
		      <div className="row justify-content-center mb-3">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">The KBM Formula</h2>
		        </div>
		      </div>
		      <div className="row align-items-stretch">
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="0">
		          <Link to="/kbm-formula/#tailored-lessons" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="uil uil-atom"></i>
		            </div>
		            <div>
		              <h3>Tailored lessons</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
		          <Link to="/kbm-formula/#flexible-scheduling" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="fa fa-calendar"></i>
		            </div>
		            <div>
		              <h3>Flexible scheduling</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
		          <Link to="/kbm-formula/#advanced-tracking" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="fa fa-map-marker"></i>
		            </div>
		            <div>
		              <h3>Advanced tracking</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
		          <Link to="/kbm-formula/#interactive-learning" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="uil uil-pen"></i>
		            </div>
		            <div>
		              <h3>Interactive learning</h3>
		            </div>
		          </Link>
		        </div>


		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="0">
		          <Link to="/kbm-formula/#goal-setting" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="fa fa-graduation-cap"></i>
		            </div>
		            <div>
		              <h3>Goal setting</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
		          <Link to="/kbm-formula/#study-skills" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="uil uil-brain"></i>
		            </div>
		            <div>
		              <h3>Study skills</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
		          <Link to="/kbm-formula/#parental-involvement" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="fa fa-users"></i>
		            </div>
		            <div>
		              <h3>Parental involvement</h3>
		            </div>
		          </Link>
		        </div>
		        <div className="col-sm-6 col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
		          <Link to="/kbm-formula/#personalised-support" className="category d-flex align-items-center h-100">
		            <div>
		              <i className="fa fa-handshake-o"></i>
		            </div>
		            <div>
		              <h3>Personalised support</h3>
		            </div>
		          </Link>
		        </div>
		        

		      </div>

		      {/*<div className="row justify-content-center" data-aos="fade-up" data-aos-delay="400">
		        <div className="col-lg-8 text-center">
		          <p>We have more category here. <Link to="#">Browse all</Link></p>
		        </div>
		      </div>*/}
		    </div>
		  </div>

		  <div className="services-section">
		    <div className="container">
		      <div className="row justify-content-between">
		        <div className="col-lg-4 mb-5 mb-lg-0">

		          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
		            <h2 className="line-bottom mb-4">Our Tutors</h2>
		          </div>
		          <p data-aos="fade-up" data-aos-delay="100">Our tutors are experienced and passionate educators dedicated to helping each student succeed. They tailor lessons to individual needs, making learning engaging, effective, and enjoyable for all students.</p>
		        
		          <ul className="list-unstyled ul-check mb-5 primary" data-aos="fade-up" data-aos-delay="200">
		            <li>Focus on building confidence and understanding</li>
		            <li>Personalised lesson plans for every student</li>
		            <li>Friendly teaching approach</li>
		          </ul>

		          <p data-aos="fade-up" data-aos-delay="300"><Link to="our-tutors" className="btn btn-primary">Read More</Link></p>

		        </div>
		        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
		          <figure className="img-wrap-2">
		            <img src="images/teacher-min.jpg" alt="Image" className="img-fluid" />
		            <div className="dotted"></div>
		          </figure>

		        </div>
		      </div>
		    </div>
		  </div>


		  {/*<div className="untree_co-section bg-light">
		    <div className="container"> 
		      <div className="row justify-content-center mb-5">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">The Right Course For You</h2>
		          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		        </div>
		      </div>
		      <div className="row">
		        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
		          <div className="custom-media">
		            <Link to="#"><img src="images/img-school-1-min.jpg" alt="Image" className="img-fluid" /></Link>
		            <div className="custom-media-body">
		              <div className="d-flex justify-content-between pb-3">
		                <div className="text-primary"><span className="uil uil-book-open"></span> <span>43 lesson</span></div>
		                <div className="review"><span className="icon-star"></span> <span>4.8</span></div>
		              </div>
		              <h3>Education Program Title</h3>
		              <p className="mb-4">Lorem ipsum dolor sit amet once is consectetur adipisicing elit optio.</p>
		              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
		                <div><span className="price">$87.00</span></div>
		                <div><Link to="#">Learn More</Link></div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
		          <div className="custom-media">
		            <Link to="#"><img src="images/img-school-2-min.jpg" alt="Image" className="img-fluid" /></Link>
		            <div className="custom-media-body">
		              <div className="d-flex justify-content-between pb-3">
		                <div className="text-primary"><span className="uil uil-book-open"></span> <span>43 lesson</span></div>
		                <div className="review"><span className="icon-star"></span> <span>4.8</span></div>
		              </div>
		              <h3>Education Program Title</h3>
		              <p className="mb-4">Lorem ipsum dolor sit amet once is consectetur adipisicing elit optio.</p>
		              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
		                <div><span className="price">$93.00</span></div>
		                <div><Link to="#">Learn More</Link></div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4 mb-lg-0">
		          <div className="custom-media">
		            <Link to="#"><img src="images/img-school-3-min.jpg" alt="Image" className="img-fluid" /></Link>
		            <div className="custom-media-body">
		              <div className="d-flex justify-content-between pb-3">
		                <div className="text-primary"><span className="uil uil-book-open"></span> <span>43 lesson</span></div>
		                <div className="review"><span className="icon-star"></span> <span>4.8</span></div>
		              </div>
		              <h3>Education Program Title</h3>
		              <p className="mb-4">Lorem ipsum dolor sit amet once is consectetur adipisicing elit optio.</p>
		              <div className="border-top d-flex justify-content-between pt-3 mt-3 align-items-center">
		                <div><span className="price">$65.00</span></div>
		                <div><Link to="#">Learn More</Link></div>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>*/}

		  <div className="untree_co-section pt-0 bg-img overlay" style={{ backgroundImage: `url('images/2.png')` }}>
		    <div className="container">
		      <div className="row align-items-center justify-content-center text-center">
		        <div className="col-lg-7">
		          <h2 className="text-white mb-3" data-aos="fade-up" data-aos-delay="0">Shaping Tomorrow’s Leaders Through Expert Tutoring</h2>
		          <p className="text-white h5 mb-4" data-aos="fade-up" data-aos-delay="100">Empowering students today with the skills and confidence needed for a brighter future.</p>
		          <p><Link to="#" className="btn btn-secondary" data-aos="fade-up" data-aos-delay="200">Enroll Now</Link></p>
		        </div>
		      </div>
		    </div>  
		  </div> 

		  <div className="untree_co-section">
		    <div className="container">
		      <div className="row justify-content-between">
		        <div className="col-lg-5 mb-5">
		          <h2 className="line-bottom mb-4" data-aos="fade-up" data-aos-delay="0">Our Achievments</h2>
		          <p data-aos="fade-up" data-aos-delay="100">Since 2011, KBM has been a leading training provider. With the KBM Tutors, we continue our commitment to personalised education. We ensure tailored support to help students achieve their academic and future career goals.</p>
		          
		          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
		            <li>Established in 2011</li>
		            <li>Expanded tutoring services</li>
		            <li>Dedicated to student success</li>
		          </ul>
		          {/*<div className="row count-numbers mb-5">
		            <div className="col-4 col-lg-4" data-aos="fade-up" data-aos-delay="0">
		              <span className="counter d-block"><span data-number="12023">0</span><span>+</span></span>
		              <span className="caption-2">No. Students</span>
		            </div>
		            <div className="col-4 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		              <span className="counter d-block"><span data-number="49">0</span><span></span></span>
		              <span className="caption-2">No. Teachers</span>
		            </div>
		            <div className="col-4 col-lg-4" data-aos="fade-up" data-aos-delay="100">
		              <span className="counter d-block"><span data-number="12">0</span><span></span></span>
		              <span className="caption-2">No. Awards</span>
		            </div>
		          </div>*/}

		          <p data-aos="fade-up" data-aos-delay="200">
		            {/*<Link to="#" className="btn btn-primary mr-1">Admission</Link>*/}
		            <Link to="/vision" className="btn btn-outline-primary">Learn More</Link>
		          </p>
		        </div>
		        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
		          <div className="bg-1"></div>
		          <Link to="https://vimeo.com/342333493" data-fancybox className="video-wrap">
		            <span className="play-wrap"><span className="icon-play"></span></span>
		            <img src="images/img-school-4-min.jpg" alt="Image" className="img-fluid rounded" />
		          </Link>
		        </div>
		      </div>
		    </div>
		  </div> 

		  {/*<div className="untree_co-section bg-light">
		    <div className="container">
		      <div className="row justify-content-center mb-5">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">School News</h2>
		          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		        </div>
		      </div>
		      <div className="row align-items-stretch">
		        <div className="col-lg-6"  data-aos="fade-up" data-aos-delay="100">
		          <div className="media-h d-flex h-100">
		            <figure>
		              <img src="images/img-school-1-min.jpg" alt="Image" />
		            </figure>
		            <div className="media-h-body">
		              <h2 className="mb-3"><Link to="#">Education for Tomorrow's Leaders</Link></h2>
		              <div className="meta mb-2"><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Admin</div>
		              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		              <p><Link to="#">Learn More</Link></p>
		            </div>
		          </div>
		        </div>
		        <div className="col-lg-6"  data-aos="fade-up" data-aos-delay="200">
		          <div className="media-h d-flex h-100">
		            <figure>
		              <img src="images/img-school-2-min.jpg" alt="Image" />
		            </figure>
		            <div className="media-h-body">
		              <h2 className="mb-3"><Link to="#">Enroll Your Kids This Summer to get 30% off</Link></h2>
		              <div className="meta mb-2"><span className="icon-calendar mr-2"></span><span>June 22, 2020</span>  <span className="icon-person mr-2"></span>Admin</div>
		              <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		              <p><Link to="#">Learn More</Link></p>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div> */}


		 {/* <div className="untree_co-section">
		    <div className="container">
		      <div className="row justify-content-center mb-5">
		        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
		          <h2 className="line-bottom text-center mb-4">Pricing</h2>
		          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		        </div>
		      </div>
		      <div className="row">
		        <div className="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="00">
		          <div className="pricing">
		            <div className="pricing-body">

		              <h3 className="pricing-plan-title">Starter</h3>
		              <div className="price"><span className="fig">$50.99</span><span>/month</span></div>
		              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		              
		              <p><Link to="#" className="btn btn-outline-primary">Get Started</Link></p>
		            </div>
		          </div>
		        </div>
		        <div className="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="200">
		          <div className="pricing">
		            <div className="pricing-body">

		              <h3 className="pricing-plan-title">Business</h3>
		              <div className="price"><span className="fig">$99.99</span><span>/month</span></div>
		              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		              
		              <p><Link to="#" className="btn btn-primary">Get Started</Link></p>
		            </div>
		          </div>
		        </div>
		        <div className="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="300">
		          <div className="pricing">
		            <div className="pricing-body">

		              <h3 className="pricing-plan-title">Premium</h3>
		              <div className="price"><span className="fig">$199.99</span><span>/month</span></div>
		              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
		              
		              <p><Link to="#" className="btn btn-outline-primary">Get Started</Link></p>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div> */}


		  {/*<div className="untree_co-section bg-light">
		    <div className="container">
		      <div className="row">
		        <div className="col-lg-7 text-center mx-auto">

		          <h3 className="line-bottom mb-4">Testimonials</h3>
		          <div className="owl-carousel wide-slider-testimonial">
		            <div className="item">
		              <blockquote className="block-testimonial">

		                <p>
		                &ldquo;Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.&rdquo;</p>
		                <div className="author">
		                  <img src="images/person_1.jpg" alt="Free template by TemplateUX" />
		                  <h3>John Doe</h3>
		                  <p className="position">CEO, Founder</p>
		                </div>
		              </blockquote>
		            </div>

		            <div className="item">
		              <blockquote className="block-testimonial">

		                <p>
		                &ldquo;When she reached the first hills of the Italic Mountains, she had a last view back on the skyline of her hometown Bookmarksgrove, the headline of Alphabet Village and the subline of her own road, the Line Lane. Pityful a rethoric question ran over her cheek, then she continued her way.&rdquo;</p>
		                <div className="author">
		                  <img src="images/person_2.jpg" alt="Free template by TemplateUX" />
		                  <h3>James Woodland</h3>
		                  <p className="position">Designer at Facebook</p>
		                </div>
		              </blockquote>
		            </div>

		            <div className="item">
		              <blockquote className="block-testimonial">

		                <p>
		                &ldquo;A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth.&rdquo;</p>
		                <div className="author">
		                  <img src="images/person_3.jpg" alt="Free template by TemplateUX" />
		                  <h3>Rob Smith</h3>
		                  <p className="position">Product Designer at Twitter</p>
		                </div>
		              </blockquote>
		            </div>
		          </div>

		        </div>
		      </div>
		    </div>
		  </div>*/}
 

		</>
	)
}

export default Home