import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const TutorsQualification = () => {
	return (
		<>

		<MetaData title="Tutors Qualifications" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Tutors Qualifications</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Tutors Qualifications</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, we pride ourselves on our team of highly qualified, experienced and dedicated professionals, selected for their strong educational background and passion for teaching.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Qualifications at glance</h4>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>PGCE Certified:</b> All tutors are PGCE certified and qualified to teach their subjects with confidence and expertise.
            </li>
            <li>
              <b>Enhanced DBS Checks:</b> Every tutor undergoes an enhanced DBS check to ensure the safety and well-being of our students.
            </li>
            <li>
              <b>Subject-Specific Expertise:</b> Each tutor academically excels in the subjects they teach, ensuring a deep understanding of the material.
            </li>
            <li>
              <b>Years of Teaching Experience:</b> Our tutors bring extensive classroom and one-on-one tutoring experience, giving them the ability to handle diverse student needs effectively and know various strategies which give our students an advantage.
            </li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Commitment to excellence</h4>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>Ongoing Training:</b> Our tutors regularly attend seminars, workshops, and professional development courses to stay updated with the latest teaching techniques and best practices.
            </li>
            <li>
              <b>Personalised Support:</b> We focus on tailoring our lessons to each student's unique learning style and goals, ensuring effective, customised support for every student.
            </li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Continuous Professional Development</h4>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>Regular Training:</b> To maintain the highest teaching standards, our tutors participate in ongoing training, keeping them informed about the latest educational tools, methods, and trends.
            </li>
            <li>
              <b>Innovative Techniques:</b> With up-to-date knowledge, our tutors incorporate new strategies and technologies into their lessons, ensuring that our students benefit from modern approaches to learning.
            </li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Dedication to Student Success</h4>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>Passion for Teaching:</b> Our tutors are not only skilled professionals, but also deeply committed to helping students succeed. Their passion creates an encouraging learning environment that motivates students to excel.
            </li>
            <li>
              <b>Tailored Support:</b> By understanding each student's individual needs, our tutors provide focused, personalised assistance that enhances confidence, improves skills, and drives academic improvement.
            </li>
          </ul>

        </div>
      </div>
      </div>
  	
		</>
	)
}

export default TutorsQualification	