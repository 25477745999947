import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const About = () => {
	return (
		<>

    <MetaData title='About Us' />

			<div className="untree_co-hero overlay" style={{ backgroundImage: `url('images/3.png')` }}>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="col-lg-6 text-center ">
              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">About Us</h1>
              <div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200">
                <p>We provide high-quality education and support. Our focus is on helping students succeed with our personalised tutoring services. KBM assists students in achieving their academic goals.</p>
              </div>

              <p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>

            </div>


          </div>

        </div>

      </div> 
    </div> 

  </div> 



  <div className="services-section">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4 mb-5 mb-lg-0">

              <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
                <h2 className="line-bottom mb-4">Our Tutors</h2>
              </div>
              <p data-aos="fade-up" data-aos-delay="100">Our tutors are experienced and passionate educators dedicated to helping each student succeed. They tailor lessons to individual needs, making learning engaging, effective, and enjoyable for all students.</p>
            
              <ul className="list-unstyled ul-check mb-5 primary" data-aos="fade-up" data-aos-delay="200">
                <li>Focus on building confidence and understanding</li>
                <li>Personalised lesson plans for every student</li>
                <li>Friendly teaching approach</li>
              </ul>

              <p data-aos="fade-up" data-aos-delay="300"><Link to="our-tutors" className="btn btn-primary">Read More</Link></p>

            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
              <figure className="img-wrap-2">
                <img src="images/teacher-min.jpg" alt="Image" className="img-fluid" />
                <div className="dotted"></div>
              </figure>

            </div>
          </div>
        </div>
      </div>


 {/* <div className="untree_co-section bg-light">
    <div className="container">
      <div className="row justify-content-center mb-5">
        <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
          <h2 className="line-bottom text-center mb-4">Our Team</h2>
          <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="0">
          <div className="staff text-center">
            <div className="mb-4"><img src="images/staff_1.jpg" alt="Image" className="img-fluid"/></div>
            <div className="staff-body">
              <h3 className="staff-name">Mina Collins</h3>
              <span className="d-block position mb-4">Teacher in Math</span>
              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              <div className="social">
                <Link to="#" className="mx-2"><span className="icon-facebook"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-twitter"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-linkedin"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="100">
          <div className="staff text-center">
            <div className="mb-4"><img src="images/staff_2.jpg" alt="Image" className="img-fluid"/></div>
            <div className="staff-body">
              <h3 className="staff-name">Anderson Matthew</h3>
              <span className="d-block position mb-4">Teacher in Music</span>
              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              <div className="social">
                <Link to="#" className="mx-2"><span className="icon-facebook"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-twitter"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-linkedin"></span></Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="200">
          <div className="staff text-center">
            <div className="mb-4"><img src="images/staff_3.jpg" alt="Image" className="img-fluid"/></div>
            <div className="staff-body">
              <h3 className="staff-name">Cynthia Misso</h3>
              <span className="d-block position mb-4">Teacher English</span>
              <p className="mb-4">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
              <div className="social">
                <Link to="#" className="mx-2"><span className="icon-facebook"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-twitter"></span></Link>
                <Link to="#" className="mx-2"><span className="icon-linkedin"></span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}



  <div className="untree_co-section pb-0">
        <div className="container"> 
          <div className="row justify-content-center mb-5">
            <div className="col-lg-7 text-center" data-aos="fade-up" data-aos-delay="0">
              <h2 className="line-bottom text-center mb-4">Expert Tutoring for Every Academic Stage</h2>
              <p>We provide personalised tutoring to help your child through every step of their learning experience and ensure they utilise their full potential.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="feature">
                <span className="uil uil-brain"></span>
                <h3>Key Stage 2</h3>
                <p>With Key Stage 2 Tutoring, we help your child build a strong foundation with clear lessons and individual support.<Link to="/service/key-stage-2">Read More</Link></p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="feature">
                <span className="uil uil-calculator-alt"></span>
                <h3>Key Stage 3</h3>
                <p>Key Stage 3 Tutoring boosts your child’s confidence and learning with simple, easy lessons tailored to their needs.<Link to="/service/key-stage-3">Read More</Link></p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
              <div className="feature">
                <span className="uil uil-book-open"></span>
                <h3>Key Stage 4</h3>
                <p>Key Stage 4 Tutoring prepares your child for important exams with clear guidance that helps them understand key concepts.<Link to="/service/key-stage-4">Read More</Link></p>
              </div>
            </div>


            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="100">
              <div className="feature">
                <span className="uil uil-book-alt"></span>
                <h3>GCSE & A-Level</h3>
                <p>GCSE & A-Level Tutoring assists your child through these crucial stages with lessons that simplify complex subjects.<Link to="/service/gcse-&-a-level">Read More</Link></p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="200">
              <div className="feature">
                <span className="uil uil-history"></span>
                <h3>11+ Exam</h3>
                <p>11+ Exam Preparation prepares your child with focused support, making studying easier and building their confidence.<Link to="/service/11+-exam">Read More</Link></p>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4" data-aos="fade-up" data-aos-delay="300">
              <div className="feature">
                <span className="uil uil-headphones"></span>
                <h3>SATs</h3>
                <p>SATs Preparation helps your child achieve great results with targeted tutoring, making learning and test prep straightforward.<Link to="/service/sat">Read More</Link></p>
              </div>
            </div>
          </div>
        </div> 
      </div>


  

  <div className="untree_co-section">

    <div className="container">
      <div className="row">
        <div className="col-lg-5 mr-auto mb-5 mb-lg-0"  data-aos="fade-up" data-aos-delay="0">
          <img src="images/img-school-5-min.jpg" alt="image" className="img-fluid"/>
        </div>
        <div className="col-lg-7 ml-auto" data-aos="fade-up" data-aos-delay="100">
          <h3 className="line-bottom mb-4">Why Choose Us</h3>
          <p>Choose us for friendly, helpful tutoring that helps students do their best and reach their goals. We make learning easy and enjoyable. </p>

          <div className="custom-accordion" id="accordion_1">
            <div className="accordion-item">
              <h2 className="mb-0">
                <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Good Teachers and Staffs</button>
              </h2>

              <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion_1">
                <div className="accordion-body">
                  <div className="d-flex">
                    <div className="accordion-img mr-4">
                      <img src="images/img-school-1-min.jpg" alt="Image" className="img-fluid"/>
                    </div>
                    <div>
                      <p>Our team works closely with students to meet their needs and provide tailored support. We focus on developing a positive and encouraging environment where students feel motivated to succeed.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> 

            <div className="accordion-item">
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">We Value Good Characters</button>
              </h2>
              <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion_1">
                <div className="accordion-body">
                  <div className="d-flex">
                    <div className="accordion-img mr-4">
                      <img src="images/img-school-2-min.jpg" alt="Image" className="img-fluid"/>
                    </div>
                    <div>
                      <p>We focus on building strong character in our students. We value respect, honesty, and responsibility, aiming to help them become successful learners and good people in everyday life.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
            <div className="accordion-item">
              <h2 className="mb-0">
                <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Your Children are Safe</button>
              </h2>

              <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion_1">
                <div className="accordion-body">
                  <div className="d-flex">
                    <div className="accordion-img mr-4">
                      <img src="images/img-school-3-min.jpg" alt="Image" className="img-fluid"/>
                    </div>
                    <div>
                      <p>We ensure a secure and caring environment with clear safety procedures and supportive staff. We prioritise creating a safe space where students can learn and grow confidently.</p>
                    </div>
                  </div>

                </div>
              </div>

            </div> 

          </div>

        </div>
      </div>
    </div>
  </div>

		</>
	)
}

export default About