import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const SafeguardingPolicy = () => {

	return (
		<>
  <MetaData title='Prevent Policy'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Prevent Policy</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Introduction</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
           KBM Tutors is committed to protecting our students from the risks related to radicalisation and extremism. Our Prevent Policy outlines our approach to ensuring that students are safeguarded from these risks and that any concerns are addressed appropriately. This policy aligns with the Prevent Duty, part of the UK government's strategy to counter terrorism.
</p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Policy statement</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">We recognise the importance of protecting our students from being drawn into terrorism or extremism. This policy sets out our approach to preventing radicalisation and ensuring that students are educated about the risks. Our goal is to promote a safe and inclusive learning environment. So all students can thrive.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Key principles</h2>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Prevent duty compliance:</b> We adhere to the Prevent Duty as outlined in the Counter-Terrorism and Security Act 2015. This requires us to have policies and procedures in place to safeguard students from radicalisation and extremist views.
            </li>
            <li><b>Staff training:</b> All staff members receive training on identifying and responding to signs of radicalisation. This training ensures that staff are aware of the risks and know how to handle concerns effectively.</li>
            <li><b>Education and awareness:</b> We provide students with information and education about the risks associated with radicalisation and extremism. This includes age-appropriate discussions and resources to help students understand and navigate these issues.</li>
            <li><b>Reporting concerns:</b> We have clear procedures for reporting any concerns related to radicalisation or extremism. Staff members are properly trained to identify warning signs and report concerns to the designated safeguarding lead.</li>
          </ul>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Relevant legislation</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Our Prevent Policy is guided by the following legislation:
          </p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Counter-Terrorism and Security Act 2015:</b> Establishes the Prevent Duty and outlines the responsibilities of educational institutions.</li>
            <li><b>Keeping Children Safe in Education (2024):</b> Provides statutory guidance on safeguarding in education, including aspects related to the Prevent Duty.</li>
            <li><b>The Prevent Duty Guidance for England and Wales (2015):</b> Outlines the requirements for implementing the Prevent Duty.
            </li>
            </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Staff responsibilities</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">All staff members at KBM Tutors are required to:</p>
            <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Identify Risks:</b> Be vigilant and identify any signs of radicalisation or extremist behaviour among students.</li>
            <li><b>Report Concerns:</b> Follow established procedures to report any concerns about radicalisation to the designated safeguarding lead.</li>
            <li><b>Promote Inclusivity:</b> Ensure that all students feel safe and included, and challenge any extremist views or behaviour.</li>
            </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Annual review</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We will review our Prevent policy annually to ensure it continues to be effective and up-to-date with current legislation. The next review is scheduled for September 6, 2025.
            </p>
            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Contact information</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">For any questions or concerns regarding this policy, please contact:</p>
            <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Safeguarding lead:</b> Waqas Yaqoob</li>
            <li><b>Email:</b> info@kbmtutors.org</li>
            <li><b>Phone:</b> +44 020 8992 4506</li>
             </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Approved by</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            <i>Salman Khan, Director</i>
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><i>06th September 2024</i></p>



        </div>
      </div>
      </div>
		</>
	)
}

export default SafeguardingPolicy