import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const SafeguardingPolicy = () => {

	return (
		<>
  <MetaData title='Safeguarding Policy'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Safeguarding Policy</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Introduction</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, safeguarding the well-being and safety of our students is our highest priority. This policy outlines our commitment to creating a secure learning environment where every student is protected from harm, abuse, and neglect. We are dedicated to ensuring that all students receive the support they need and that any safeguarding issues are managed promptly and effectively.
            </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Policy statement</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">Through this policy, we ensure the safety and well-being of students at all times. We have implemented comprehensive measures to prevent abuse and neglect. We are committed to addressing any safeguarding concerns with urgency and care. This policy is integral to our operations and reflects our commitment to safeguarding all aspects of our educational activities.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Key principles</h2>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Equality Act 2010:</b> This law protects individuals from unfair treatment and promotes equality.</li>
            <li><b>Human Rights Act 1998:</b> This law ensures that everyone's rights to fairness, respect, and dignity are protected.</li>
            <li><b>Special Educational Needs and Disability Act 2001:</b> We are committed to providing necessary adjustments to support learners with disabilities.</li>
          </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Our commitments</h2>
          </div>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Priority on safety:</b> Protecting our students is our most important responsibility. All staff members are trained to identify potential safeguarding issues and take appropriate action to address them.
            </li>
            <li><b>Clear reporting procedures:</b> We have established clear procedures for reporting and managing safeguarding concerns. These procedures are designed to ensure that concerns are handled promptly and with the necessary sensitivity.</li>
            <li><b>Safe recruitment practices:</b> Our recruitment process includes thorough background checks, including DBS (Disclosure and Barring Service) and vetting processes to ensure that all staff members are suitable and qualified to work with children and vulnerable individuals.</li>
            </ul>

        <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Relevant legislation</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Our safeguarding practices are informed by the following legislation:
          </p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Children Act 1989 and 2004:</b> Provides the legal framework for the protection of children.</li>
            <li><b>Education Act 2002:</b> Sets out the requirements for safeguarding in educational settings.</li>
            <li><b>Working Together to Safeguard Children (2018):</b> Guides collaborative safeguarding efforts across agencies.
            </li>
            <li><b>Keeping Children Safe in Education (2024):</b> Statutory guidance for schools and colleges, updated on September 2, 2024.</li>
            <li><b>The Safeguarding Vulnerable Groups Act 2006:</b> Establishes procedures for vetting individuals who work with vulnerable groups.</li>
            </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Staff responsibilities</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">All staff members at KBM Tutors are required to:</p>
            <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Report concerns:</b> Immediately report any safeguarding concerns to the designated safeguarding lead, following established procedures.</li>
            <li><b>Adhere to procedures:</b> Follow all safeguarding procedures meticulously to ensure concerns are addressed effectively and sensitively.</li>
             </ul>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Annual review</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We will review this policy to ensure its continued effectiveness and alignment with current legislation. The next review is scheduled for September 6, 2025.
            </p>
            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Contact information</h2>
          </div>
            <p data-aos="fade-up" data-aos-delay="100">For any questions or concerns regarding this policy, please contact:</p>
            <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li><b>Safeguarding lead:</b> Waqas Yaqoob</li>
            <li><b>Email:</b> info@kbmtutors.org</li>
            <li><b>Phone:</b> +44 020 8992 4506</li>
             </ul>

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Approved by</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            <i>Salman Khan, Director</i>
            </p>
            <p data-aos="fade-up" data-aos-delay="100"><i>06th September 2024</i></p>



        </div>
      </div>
      </div>
		</>
	)
}

export default SafeguardingPolicy