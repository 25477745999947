import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
	return (
		<div>
			<div className="site-footer">


		    <div className="container">

		      <div className="row">
		        <div className="col-lg-3 mr-auto">
		          <div className="widget">
		            <h3>About Us</h3>
		            <p>We provide high-quality education and support. Our focus is on helping students succeed with our personalised tutoring services. KBM assists students in achieving their academic goals.</p>
		          </div> 
		          <div className="widget">
		            <h3>Connect</h3>
		            <ul className="list-unstyled social">
		              <li><Link target="_blank" to="https://www.instagram.com/kbm_tutors/"><span className="icon-instagram"></span></Link></li>
		              <li><Link target="_blank" to="https://x.com/KBM_Tutors"><span className="icon-twitter"></span></Link></li>
		              <li><Link target="_blank" to="https://www.facebook.com/profile.php?id=61562584149528"><span className="icon-facebook"></span></Link></li>
		              <li><Link target="_blank" to="https://www.youtube.com/@KBMTutors"><span className="icon-youtube"></span></Link></li>
		              {/*<!-- <li><Link to="#"><span className="icon-pinterest"></span></Link></li>*/}
		              {/*<li><Link to="#"><span className="icon-dribbble"></span></Link></li> -->*/}
		            </ul>
		          </div> 
		        </div> 

		        <div className="col-lg-2 ml-auto">
		          <div className="widget">
		            <h3>Quick Links</h3>
		            <ul className="list-unstyled float-left links">
		              <li><Link to="/">Home</Link></li>
		              <li><Link to="/teaching-method">Teaching Methods</Link></li>
		              <li><Link to="/progress-tracking">Progress Tracking</Link></li>
		              <li><Link to="/blogs">Blog</Link></li>
		              <li><Link to="/faq">FAQs</Link></li>
		            </ul>
		          </div> 
		        </div> 

		        <div className="col-lg-3">
		          <div className="widget">
		            <h3>Policies</h3>
		            <ul className="list-unstyled float-left links">
		              <li><Link to="/equality-policy">Equality Policy</Link></li>
		              <li><Link to="/safeguarding-policy">Safeguarding Policy</Link></li>
		              <li><Link to="/prevent-policy">Prevent Policy</Link></li>
		              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
		              <li><Link to="/cookie-policy">Cookie Policy</Link></li>
		            </ul>
		          </div> 
		        </div> 


		        <div className="col-lg-3">
		          <div className="widget">
		            <h3>Contact</h3>
		            <address>1 Concord Business Centre, Concord Road, London , W3 0TJ</address>
		            <span>Mon - Fri : 09:00 AM - 09:00 PM</span><br></br>
		            <span>Sat - Sun : 09:00 AM - 06:00 PM</span>
		            <ul className="list-unstyled links mt-2 mb-4">
		              <li><Link to="tel:+44 020 8992 4506">+44 020 8992 4506</Link></li>
		              <li><Link to="mailto:info@kbmtutors.org">info@kbmtutors.org</Link></li>
		            </ul>
		          </div> 
		        </div> 

		      </div> 

		      <div className="row">
		        <div className="col-12 text-center">
		          <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script>. All Rights Reserved. &mdash; KBM
		          </p>
		        </div>
		      </div> 
		    </div> 
		  </div>
		</div>
	)
}

export default Footer