import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Vision = () => {

	return (
		<>
  <MetaData title='Mission & Vision'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/3.png')` }}>
    <div className="container">
      <div className="row align-items-center justify-content-center">
        <div className="col-12">
          <div className="row justify-content-center ">
            <div className="col-lg-6 text-center ">
              <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Mission & Vision</h1>
              {/*<div className="mb-5 text-white desc mx-auto" data-aos="fade-up" data-aos-delay="200">
                <p>Another free template by Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live.</p>
              </div>

              <p className="mb-0" data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-secondary">Explore courses</Link></p>*/}

            </div>


          </div>

        </div>

      </div> 
    </div> 

  </div> 



  <div className="services-section">
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-5 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Mission</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, our mission is to help students reach their academic potential while developing a genuine love for learning. We offer personalised tutoring that builds confidence and curiosity, recognising that every student has their own strengths and challenges.
We create a friendly and supportive environment where students feel valued and understood. Our tutors use engaging teaching methods and resources to make learning enjoyable, simplifying complex concepts to make them easier to grasp.
We also maintain open communication with parents to ensure that every student gets the support they need to succeed. Our goal is to encourage a positive attitude towards education, helping students embrace challenges and achieve their best. Together, we equip them with the knowledge and confidence to thrive further than just their studies and beyond.
          </p>

          <p data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-primary">Get Started</Link></p>

        </div>
        <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
          <figure className="img-wrap-2">
            <img src="images/teacher-min.jpg" alt="Image" className="img-fluid"/>
            <div className="dotted"></div>
          </figure>

        </div>
      </div>
    </div>
  </div>

 <div className="services-section">
    <div className="container">
      <div className="row justify-content-between">
      <div className="col-lg-6" data-aos="fade-up" data-aos-delay="0">
          <figure className="">
            <img src="images/2.png" alt="Image" className="img-fluid"/>
            <div className="dotted"></div>
          </figure>

        </div>
        <div className="col-lg-6 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Vision</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, our vision is to create a world where every student has equal capability to progress academically and personally. We see a future where learning goes beyond just passing exams; it is about exploring interests, developing skills and becoming confident, well-rounded individuals.
We aim to be a trusted partner in education, providing a positive and engaging learning environment that inspires students to reach for their goals. We believe that with the right support, every student can unlock their potential and find joy in learning. Our vision is to help create a generation of learners who are curious, resilient, and ready to take on the challenges of the future.
          </p>

          <p data-aos="fade-up" data-aos-delay="300"><Link to="#" className="btn btn-primary">Get Started</Link></p>

        </div>
      </div>
    </div>
  </div>

		</>
	)
}

export default Vision