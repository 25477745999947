import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const Faq = () => {

	const faqData = [
  {
    question: "What are the subjects you offer tutoring in?",
    answer: "We offer tutoring in core subjects, including English, Maths, and Science, as well as other subjects like History, Geography, and Foreign Languages.",
  },
  {
    question: "How are your tutors qualified?",
    answer: "Our tutors are highly qualified; many hold degrees in their subject areas, plus teaching certifications. They have experience teaching students at levels from beginning to advanced.",
  },
  {
    question: "Where are your tutoring sessions held?",
    answer: "Our tutoring sessions are either conducted face-to-face, one-on-one at our local tutoring centres, or in the comfort of your homes, according to preference and distance.",
  },
  {
    question: "What age groups do you tutor?",
    answer: "We tutor students across all Key Stages, from primary school through to GCSE and A-Level.",
  },
  {
    question: "Do you provide one-on-one tutoring?",
    answer: "Yes, we do offer one-on-one tutoring to provide individualised support that targets the unique needs of each and every student.",
  },
  {
    question: "How long is each session?",
    answer: "These are normally 1 to 2 hours long, although for individual cases, this may vary depending on the needs of the student.",
  },
  {
    question: "Can I request a specific tutor for my child?",
    answer: "We match students with tutors based on a careful consideration of student needs and subject requirements; however, we do accommodate requests where possible.",
  },
  {
    question: "How will you monitor the progress of the students?",
    answer: "Feedback is provided regularly to the students and parents regarding progress through reports and assessments, which map improvement.",
  },
  {
    question: "Do you prepare students for exams?",
    answer: "Yes, we provide personalised tutoring to help students prepare for SATs, GCSEs, and A-Levels, with a focus on exam techniques along with the subject knowledge.",
  },
  {
    question: "How do you cater to students with learning difficulties?",
    answer: "We provide personalised learning plans which take into account the specific needs the students have because of learning difficulties and ensure that they get exactly what is needed by such students to have them thrive in their education.",
  },
  {
    question: "How much do you charge for your tutoring services?",
    answer: "Our prices vary by subject, level, and frequency. For specific pricing, please contact us directly.",
  },
  {
    question: "How flexible is your scheduling?",
    answer: "We can work around your family's schedule and offer sessions during the day, evening, and weekends.",
  },
  {
    question: "Do you provide group tutoring sessions?",
    answer: "Yes, we offer small group sessions that can be more budget-friendly and allow for learning with a peer.",
  },
  {
    question: "Can tutoring be arranged for short-term needs only, say for exam revision?",
    answer: "Yes, we have short-term packages tailor-made for this particular need of revision and preparation for exams.",
  },
  {
    question: "How do I get your services for my child?",
    answer: "Just call and discuss your requirements for your child, and we will fix a consultation to tailor a custom tutoring plan.",
  },
  {
    question: "What is the minimum commitment for your services?",
    answer: "We have available options for both long-term and short-term with absolutely no minimum commitment.",
  },
  {
    question: "How do you determine a student's needs?",
    answer: "We start with an initial consultation alongside an assessment that identifies your child's strengths and those areas needing improvement.",
  },
  {
    question: "What qualifications do your tutors have in their subject?",
    answer: "Our tutors have academic qualifications combined with teaching experience in the subjects they are tutoring.",
  },
  {
    question: "Do you tutor during school holidays?",
    answer: "Yes, we offer tutoring sessions all over the school holidays in order to keep learners catching up or staying on track in certain areas that might need attention.",
  },
  {
    question: "How will I know if the tutoring is working for my child?",
    answer: "We will keep you informed about your child's progress, including feedback regarding their performance and improvement in those problem areas.",
  }
];


	return (
		<>

		<MetaData title="FAQs" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">FAQs</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">


		    <div className="container">
		      <div className="row">
		        
		        <div className="col-lg-12" data-aos="fade-up" data-aos-delay="100">
		          <h3 className="line-bottom mb-4">FAQs</h3>

		          <div className="custom-accordion" id="accordion_1">
		          {faqData.map((faq, index) => (
		            <div className="accordion-item" key={index}>
		              <h2 className="mb-0">
		                <button className="btn btn-link" type="button" data-toggle="collapse" data-target={`#collapse${index}`} aria-expanded={index === 0 ? 'true' : 'false'} aria-controls={`collapse${index}`}>{faq.question}</button>
		              </h2>

		              <div id={`collapse${index}`} className={`collapse ${index === 0 ? 'show' : ''}`} aria-labelledby={`heading${index}`} data-parent="#accordion_1">
		                <div className="accordion-body">
		                  <div className="d-flex">
		                    <div>
		                    <p>
		                    {faq.answer}
		                    </p>
		                    </div>
		                  </div>
		                </div>
		              </div>
		            </div>
		            ))} 

		            

		          </div>

		        </div>
		      </div>
		    </div>
		  </div>	
		</>
	)
}

export default Faq	