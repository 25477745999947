import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const BookConsultation = () => {

	return (
		<>
  <MetaData title='Book Consultation'/>
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Book a Free Session</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div>




  <div className="untree_co-section">
    <div className="container">

      <div className="row mb-5">
        <div className="col-lg-6 mb-5 order-2 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
          <div className="contact-info">

            <div className="address">
              <i className="icon-room"></i>
              <h4 className="mb-2">Location:</h4>
              <p>1 Concord Business Centre, Concord Road, London , W3 0TJ</p>
            </div>

            <div className="open-hours mt-4">
              <i className="icon-clock-o"></i>
              <h4 className="mb-2">Open Hours:</h4>
              <p>
                Monday-Friday:
                09:00 AM - 09:00 PM
              </p>
              <p>
                Saturday-Sunday:
                09:00 AM - 06:00 PM
              </p>
            </div>

            <div className="email mt-4">
              <i className="icon-envelope"></i>
              <h4 className="mb-2">Email:</h4>
              <p>info@kbmtutors.org</p>
            </div>

            <div className="phone mt-4">
              <i className="icon-phone"></i>
              <h4 className="mb-2">Call:</h4>
              <p>+44 020 8992 4506</p>
            </div>

          </div>
        </div>
        <div className="col-lg-6 mr-auto order-1" data-aos="fade-up" data-aos-delay="200">
          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Book a Free Session</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">We offer consultations on an individual basis to understand your needs. How can we help the child meet their educational needs? Whether you need specific tuition or general academic guidance, feel free to contact us.</p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>Understand the strengths and weaknesses of your child</li>
            <li>Give insights about our approach and methodology</li>
            <li>Explore your child's learning style and preference</li>
            <li>Create an individual learning plan</li>
          </ul>
          <p data-aos="fade-up" data-aos-delay="100">Our dedicated team is ready to answer your questions. We offer the support your child needs to succeed.</p>
          <p data-aos="fade-up" data-aos-delay="100">Please visit us or contact us via email or telephone to schedule your consultation and take the first step to unlocking your child's full potential!</p>
        </div>
      </div>

      
    </div>
  </div>
		</>
	)
}

export default BookConsultation