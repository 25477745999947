import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const ProgressTracking = () => {
	return (
		<>

		<MetaData title="Progress Tracking" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Progress Tracking</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Progress Tracking</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, we believe that consistent progress tracking is key to unlocking each student's full potential. Our approach ensures that every step of their academic journey is monitored and adjusted to maximise success. Here's how we make it work:
          </p>

          <ul className="ul-check list-unstyled mb-5 primary" data-aos="fade-up" data-aos-delay="200">
            <li>
              <b>Personalised Student Reports:</b> We provide detailed reports that give insights into your child's strengths and areas for improvement. These reports allow us to tailor the lessons to focus on what matters most.
            </li>
            <li>
              <b>Frequent Assessments:</b> Regular quizzes, mock exams, and interactive activities help us gauge students’ understanding and retention of material. These assessments ensure that students are on track and give us the opportunity to adjust lesson plans if necessary.
            </li>
            <li>
              <b>Parent Communication:</b> We keep parents updated with real-time feedback and quarterly meetings. These check-ins allow us to discuss your child's progress, challenges, and the strategies we're implementing to ensure continued growth.
            </li>
            <li>
              <b>Goal Setting:</b> We work with each student to set achievable short- and long-term goals. Whether it's preparing for a specific exam or mastering foundational skills, these goals help guide our lesson plans and build confidence as students hit milestones.
            </li>
            <li>
              <b>Student Portal:</b> Our online platform gives students access to their progress reports, upcoming tasks, and completed work. This portal allows students and parents to stay involved in the learning process, ensuring accountability and transparency.
            </li>
          </ul>

        </div>
      </div>
      </div>
  	
		</>
	)
}

export default ProgressTracking	