import React from 'react';
import {Link,useParams} from 'react-router-dom';
import MetaData from './MetaData.js';
import categoryData from '../categoryData.js';


const FormulaDetailPage = () => {

  const { name } = useParams();

  // const categoryData = {
  //   'tailored-lessons': {
  //     title: 'Tailored Lessons',
  //     description: 'These lessons are customized to fit your needs...',
  //     courses: 1391,
  //   },
  //   // Add other categories here
  // };

  const category = categoryData[name] || {};

  // const category = categoryData.find((category) => category.title.replace(/\s+/g, '-') === title.replace(/\s+/g, '-'));
  // const { title, description, courses } = category;

	return (
		<>

    <MetaData title={category.title} />
			
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('../../images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">{category.title}</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


      <div className="untree_co-section">
        <div className="container">
          <div className="col-lg-12 mb-5 mb-lg-0">

            <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
              <h2 className="line-bottom mb-4">{category.title}</h2>
            </div>

            <p data-aos="fade-up" data-aos-delay="100">{category.description}</p>

          </div>
        </div>
      </div>
  


		</>
	)
}

export default FormulaDetailPage