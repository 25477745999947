import React from 'react';
import {Link} from 'react-router-dom';
import MetaData from './MetaData.js';

const FlexibleScheduling = () => {
	return (
		<>

		<MetaData title="Flexible Scheduling" />
			<div className="untree_co-hero overlay halfBanner" style={{ backgroundImage: `url('images/1.png')` }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12">
              <div className="row justify-content-center ">
                <div className="col-lg-6 text-center ">
                  <h1 className="mb-4 heading text-white" data-aos="fade-up" data-aos-delay="100">Flexible Scheduling</h1>
                  
                </div>


              </div>

            </div>

          </div> 
        </div> 

      </div> 


		
		<div className="untree_co-section">
      <div className="container">
        <div className="col-lg-12 mb-5 mb-lg-0">

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h2 className="line-bottom mb-4">Flexible Scheduling</h2>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            At KBM Tutors, we understand that students are busy, and our goal is to make sure tutoring fits smoothly into your child and your own schedules. With our flexible options, your child can balance school, extracurricular activities, and personal commitments without added stress.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Customised Timetable Options</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            We offer flexible learning schedules to fit your child’s needs. Whether you prefer morning, afternoon, or evening sessions, we can accommodate your availability. This allows your child to keep up with their studies while also having time for other extra curricular activities. 
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Easy Rescheduling</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Life can be unpredictable, and we recognise the need for flexibility. If an unexpected event comes up, you can easily reschedule sessions without hassle. Our aim is to ensure your child stays on track without being locked into a rigid schedule.
          </p>

          <div className="section-title mb-3" data-aos="fade-up" data-aos-delay="0">
            <h4 className="line-bottom mb-4">Maximised Learning Efficiency</h4>
          </div>

          <p data-aos="fade-up" data-aos-delay="100">
            Our flexible scheduling ensures that tutoring happens when your child is most focused and ready to learn. This helps reduce stress and maximises attention, leading to a more effective learning experience and better academic results.
          </p>

        </div>
      </div>
      </div>
  	
		</>
	)
}

export default FlexibleScheduling	